//3rd party
import * as Icon from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';

//styles
import './schadeGegevens.css';
import 'react-loading-skeleton/dist/skeleton.css';

const SchadeGegevens = (props) => {
    return (
        <>
        <table id="info">
            <tbody>
                <tr>
                    <td>Kenmerk TVM:</td>
                    <td>{props.schade ? props.schade.kenmerk_tvm : <Skeleton height={20} />}</td>
                </tr>
                <tr>
                    <td>Nota:</td>
                    <td>{props.schade ? props.schade.nota : <Skeleton height={20} />}</td>
                </tr>
                <tr>
                    <td>Datum:</td>
                    <td>{props.schade ? moment(props.schade.datum).format('DD-MM-YYYY') : <Skeleton height={20} />}</td>
                </tr>
                <tr>
                    <td>{props.schade && props.schade.chauffeur_medewerker ? 'Chauffeur:' : 'Charter:'}</td>
                    {
                        props.schade ?
                        <td>{props.schade.chauffeur_medewerker ? props.schade.chauffeur_medewerker.naam : props.schade.charter.naam}</td>
                        :
                        <td><Skeleton height={20} /></td>
                    }
                </tr>
                <tr>
                    <td>Planner:</td>
                    <td>{props.schade ? props.schade.planner_melder.naam : <Skeleton height={20} />}</td>
                </tr>
                <tr>
                    <td>Kenteken:</td>
                    <td>{props.schade ? <span className="vehicle" onClick={() => props.navigate('/kenteken/' + props.schade.kenteken)}>{props.schade.kenteken}{props.schade.companyInfo.companyName ? <span className="vehicle-company">({props.schade.companyInfo.companyName})</span> : null}</span> : <Skeleton height={20} />}</td>
                </tr>
                <tr>
                    <td>Kenteken trailer/aanhanger:</td>
                    <td>{props.schade ? props.schade.kenteken_trailer_aanhanger : <Skeleton height={20} />}</td>
                </tr>
                <tr>
                    <td>{props.schade.soort_schade === 'ladingschade' ? 'Opdrachtgever:' : 'Tegenpartij:'}</td>
                    <td>{props.schade ? props.schade.tegenpartij : <Skeleton height={20} />}</td>
                </tr>
            </tbody>
        </table>
        {
            props.schade.merged_schades && props.schade.merged_schades.length > 0 ?
            <div className="merged-schades">
                <p>Gekoppeld aan:</p>
                <div className="merged-buttons">
                    {
                        props.schade.merged_schades.map((item, index) =>  {
                            return <button key={index} onClick={() => {props.getSchade(item.id); props.navigate('/schades/' + item.id)}}>{item.kenmerk}</button>
                        })
                    }
                </div>
            </div>
            :
            null
        }
        </>
    );
};
export default SchadeGegevens;