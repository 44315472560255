import { useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";

//3rd party
import {Accordion, AccordionBody, AccordionHeader, AccordionItem} from "react-headless-accordion";
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import * as Icon from 'react-bootstrap-icons';

//styles
import './sidenav.css';

//Assets
import vsdvLogo from '../../assets/images/logo.svg';
import svzLogo from '../../assets/images/svz-logo.svg';

const SideNav = (props) => {

    //Declare variables
    const navigate = useNavigate();
    const medewerker = JSON.parse(localStorage.getItem('medewerker'));
    const dieselpercentages_inzien = medewerker.dieselpercentages_inzien ? medewerker.dieselpercentages_inzien : false;
    const page = props.page;
    const [day, setDay] = useState(moment().format('dddd D MMM YYYY'));
    const [time, setTime] = useState(moment().format('HH:mm'));
    //Set navitems based on current user role
    const navitems = [
        {
            name: 'Dashboard',
            link: '/',
            icon: <Icon.House />,
            roles: ['authenticated', 'gebruiker_en_schade_melden', 'controleur', 'super_admin', 'schadebeheer', 'wagenpark', 'wagenpark_en_controleur', 'loonadministratie'],
        },
        {
            name: 'Uren',
            link: null,
            icon: <Icon.ClockHistory />,
            roles: ['authenticated', 'gebruiker_en_schade_melden', 'controleur', 'super_admin', 'schadebeheer', 'wagenpark', 'wagenpark_en_controleur', 'loonadministratie'],
            subitems: [
                {
                    name: 'Uren registreren',
                    link: '/registreren',
                    icon: <Icon.ArrowRightShort />,
                    roles: ['authenticated', 'gebruiker_en_schade_melden', 'controleur', 'super_admin', 'schadebeheer', 'wagenpark', 'wagenpark_en_controleur', 'loonadministratie']
                },
                {
                    name: 'Uren controleren',
                    link: '/uren-controleren',
                    icon: <Icon.ArrowRightShort />,
                    roles: ['controleur', 'wagenpark_en_controleur', 'super_admin', 'loonadministratie'],
                    is_vervanger: true
                },
                {
                    name: 'Alle registraties',
                    link: '/alle-registraties',
                    icon: <Icon.ArrowRightShort />,
                    roles: ['super_admin', 'loonadministratie']
                },
            ]
        },
        // {
        //     name: 'Declaraties',
        //     link: null,
        //     icon: <Icon.Receipt />,
        //     roles: ['authenticated', 'controleur', 'super_admin', 'schadebeheer', 'wagenpark', 'wagenpark_en_controleur', 'loonadministratie'],
        //     subitems: [
        //         {
        //             name: 'Declareren',
        //             link: '/declareren',
        //             icon: <Icon.ArrowRightShort />,
        //             roles: ['authenticated', 'controleur', 'super_admin', 'schadebeheer', 'wagenpark', 'wagenpark_en_controleur', 'loonadministratie']
        //         },
        //         {
        //             name: 'Declaraties controleren',
        //             link: '/declaraties-controleren',
        //             icon: <Icon.ArrowRightShort />,
        //             roles: ['controleur', 'wagenpark_en_controleur', 'super_admin', 'loonadministratie'],
        //             is_vervanger: true
        //         },
        //         {
        //             name: 'Alle declaraties',
        //             link: '/alle-declaraties',
        //             icon: <Icon.ArrowRightShort />,
        //             roles: ['super_admin', 'loonadministratie']
        //         },
        //     ]
        // },
        {
            name: 'Schadebeheer',
            link: null,
            icon: <Icon.Bandaid />,
            roles: ['super_admin', 'gebruiker_en_schade_melden', 'schadebeheer', 'wagenpark', 'wagenpark_en_controleur'],
            subitems: [
                {
                    name: 'Schades',
                    link: '/schades',
                    icon: <Icon.ArrowRightShort />,
                    roles: ['super_admin', 'schadebeheer']
                },
                {
                    name: 'Schademeldingsformulier',
                    link: '/schadeformulier',
                    icon: <Icon.ArrowRightShort />,
                    roles: ['super_admin', 'schadebeheer', 'gebruiker_en_schade_melden']
                },
                {
                    name: 'Wagenpark',
                    link: '/wagenpark',
                    icon: <Icon.ArrowRightShort />,
                    roles: ['super_admin', 'schadebeheer', 'wagenpark', 'wagenpark_en_controleur']
                },
                {
                    name: 'Gearchiveerd',
                    link: '/gearchiveerd',
                    icon: <Icon.ArrowRightShort />,
                    roles: ['super_admin', 'schadebeheer']
                },
                {
                    name: 'Werkbon algemeen',
                    link: '/werkbon-algemeen',
                    icon: <Icon.ArrowRightShort />,
                    roles: ['super_admin', 'schadebeheer', 'wagenpark', 'wagenpark_en_controleur']
                },
            ]
        },
        {
            name: 'Debiteuren',
            link: '/debiteuren',
            icon: <Icon.PersonLinesFill />,
            roles: ['super_admin'],
            dieselpercentage: true,
        },
        {
            name: 'DOT versturen',
            link: '/dieselpercentages-versturen',
            icon: <Icon.EnvelopeAt />,
            roles: ['super_admin'],
            dieselpercentage: true,
        },
        {
            name: 'Prikbord',
            link: '/prikbord',
            icon: <Icon.ChatText />,
            roles: ['authenticated', 'gebruiker_en_schade_melden', 'controleur', 'super_admin', 'schadebeheer', 'wagenpark', 'wagenpark_en_controleur', 'loonadministratie'],
            subitems: [
                {
                    name: 'Mijn items',
                    link: '/prikbord',
                    icon: <Icon.ArrowRightShort />,
                    roles: ['authenticated', 'gebruiker_en_schade_melden', 'controleur', 'super_admin', 'schadebeheer', 'wagenpark', 'wagenpark_en_controleur', 'loonadministratie']
                },
                {
                    name: 'Aanmaken',
                    link: '/prikbord/nieuw',
                    icon: <Icon.ArrowRightShort />,
                    roles: ['controleur', 'super_admin', 'schadebeheer', 'wagenpark_en_controleur', 'loonadministratie']
                }
            ]
        },
        {
            name: 'Wiki',
            link: '/wiki',
            icon: <Icon.InfoCircle />,
            roles: ['authenticated', 'gebruiker_en_schade_melden', 'controleur', 'super_admin', 'schadebeheer', 'wagenpark', 'wagenpark_en_controleur', 'loonadministratie']
        },
        {
            name: 'Instellingen',
            link: '/instellingen',
            icon: <Icon.Gear />,
            roles: ['authenticated', 'gebruiker_en_schade_melden', 'controleur', 'super_admin', 'schadebeheer', 'wagenpark', 'wagenpark_en_controleur', 'loonadministratie']
        }
    ];

    //Function to logout from portal
    const logout = () => {
        localStorage.removeItem('bearerToken');
        localStorage.removeItem('ingelogd');
        localStorage.removeItem('medewerker');
        localStorage.removeItem('videotutorials');
        navigate('/login');
    }

    useEffect(() => {
        setInterval(function () {
          setTime(moment().format('HH:mm'));
        }, 10000);
      }, []);

    return (
        <div id="sidenav">
            <div className="nav-header">
                <img src={svzLogo} alt="van Straalen de Vries" />
                <div className="datetime">
                    <p className="day">{day}</p>
                    <p className="time">{time}</p>
                </div>
                {/* <div className="menu-items">
                    {
                        navitems.map((item, index) =>  {
                            if (item.roles.includes(medewerker.gebruiker.role.type)) {
                                return (
                                    <Link key={index} to={item.link} className={page === item.link ? 'item current' : 'item'}>{item.icon}<span>{item.name}</span></Link>
                                )
                            }
                        })
                    }
                </div> */}


                <div className="menu-items">
                    <Accordion>
                    {
                        navitems.map((item, index) =>  {
                            if (item.roles.includes(medewerker.gebruiker.role.type) && item.subitems) {
                                const open = item.subitems.findIndex(item => item.link === page);
                                return (
                                    <AccordionItem key={index} isActive={open > -1}>
                                        <AccordionHeader className={open > -1 ? 'top item active' : 'top item'}>
                                            {item.icon}{item.name}
                                        </AccordionHeader>
                                        <AccordionBody>
                                            {
                                                item.subitems.map((subitem, index) =>  {
                                                    if (subitem.roles.includes(medewerker.gebruiker.role.type) || (subitem.is_vervanger === medewerker.is_vervanger)) {
                                                        return (
                                                            <Link key={index} to={subitem.link} className={page === subitem.link ? 'item subitem current' : 'item subitem'}>{subitem.icon}<span>{subitem.name}</span></Link>
                                                        )
                                                    }
                                                })
                                            }
                                        </AccordionBody>
                                    </AccordionItem>
                                )
                            }
                            if ((item.roles.includes(medewerker.gebruiker.role.type) || (item.dieselpercentage === dieselpercentages_inzien))) {
                                return (
                                    <Link key={index} to={item.link} className={page === item.link ? 'item current' : 'item'}>{item.icon}<span>{item.name}</span></Link>
                                )
                            }
                        })
                    }
                    </Accordion>
                </div>

                <div className="bottom">
                    <p className="logout" onClick={logout}><Icon.BoxArrowLeft /> Uitloggen</p>
                </div>
            </div>
        </div>
    );
};
export default SideNav;