//3rd party
import * as Icon from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';

//styles
import './schadeBedragen.css';
import 'react-loading-skeleton/dist/skeleton.css';

const SchadeBedragen = (props) => {

    const formatBedrag = (bedrag) => {
        bedrag = Number(bedrag).toFixed(2);
        bedrag = bedrag.replace(".", ",");
        bedrag = '€ ' + bedrag;
        return bedrag;
    }

    return (
        <div className="schadebedragen">
            <div className="info three-column">
                <div className="info-content">
                    <p>Schadebedrag</p>
                    <span>{props.schade ? formatBedrag(props.schade.schadebedrag) : <Skeleton height={20} />}</span>
                </div>
            </div>
            <div className="info three-column">
                <div className="info-content">
                    <p>Schadebedrag tegenpartij</p>
                    <span>{props.schade ? formatBedrag(props.schade.schadebedrag_tegenpartij) : <Skeleton height={20} />}</span>
                </div>
            </div>
            <div className="info three-column">
                <div className="info-content">
                    <p>Eigen risico</p>
                    <span>{props.schade ? formatBedrag(props.schade.eigen_risico) : <Skeleton height={20} />}</span>
                </div>
            </div>
            <div className="info two-column">
                <div className="info-content">
                    <p>Type schade</p>
                    <span>{props.schade ? props.schade.schadetype.naam : <Skeleton height={20} />}</span>
                </div>
            </div>
            <div className={'info two-column ' + (props.schade.schuldschade ? 'red' : '')}>
                <div className="info-content">
                    <p>Schuld</p>
                    <span>{props.schade ? (props.schade.schuldschade ? 'Ja' : 'Nee') : <Skeleton height={20} />}</span>
                </div>
            </div>
            <div className="info two-column">
                <div className="info-content">
                    <p>Betaald door TVM</p>
                    <span>{props.schade ? formatBedrag(props.schade.betaald_door_tvm) : <Skeleton height={20} />}</span>
                </div>
            </div>
            <div className="info two-column">
                <div className="info-content">
                    <p>WA ER</p>
                    <span>{props.schade ? formatBedrag(props.schade.wa_er) : <Skeleton height={20} />}</span>
                </div>
            </div>
        </div>
    );
};
export default SchadeBedragen;