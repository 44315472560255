import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

//Styles
import '../../../index.css';
import '../css/public.css';

//Assets
import vsdvLogo from '../../../assets/images/logo.svg';
import gifOffline from '../../../assets/images/gif-offline.gif';

const Offline = () => {

    const navigate = useNavigate();

    return (
        <div id="maintenance">
            <div className="maintenance-content">
                <img src={vsdvLogo} className="logo" />
                <p>Het personeelsportaal is momenteel niet beschikbaar. Probeer het later nog eens opnieuw of neem contact op met ICT: j.roos@vsdv.nl</p>
                <button className="red-button" style={{display: 'inline-block', marginTop: 10, marginBottom: 30}} onClick={() => navigate('/')}>Terug naar dashboard</button>
                <img src={gifOffline} className="gif-offline" />
            </div>
        </div>
      );
  
};
export default Offline;