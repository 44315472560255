import { useState, useEffect, useMemo } from 'react';

//3rd party
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import * as Icon from 'react-bootstrap-icons';
import { Tooltip } from 'react-tooltip';
import InputMask from 'react-input-mask';

//styles
import './registratiedag.css';
import 'react-tooltip/dist/react-tooltip.css';

//Assets
import feestdagImage from '../../assets/images/feestdag.png';

const Registratiedag = (props) => {

    //Declare variables
    const getTimeFromSeconds = props.getTimeFromSeconds;
    if (props.type === 'controleur' || props.type === 'wagenpark_en_controleur') {
        var result = props.allRegistraties.find(item => item.id === props.registraties.id);
    } else {
        var result = props.allRegistraties.find(item => item.weeknummer === props.weekNumber);
    }
    const [uren, setUren] = useState([]);
    const monthNow = moment(props.dayInfo.datum).format('MMMM');
    const [kilometers, setKilometers] = useState(0);
    const [storingsdienst, setStoringsdienst] = useState(false);
    const [opmerking, setOpmerking] = useState('');
    const [correctie, setCorrectie] = useState(props.dayInfo.correctie);
    const [disabled, setDisabled] = useState(props.status !== 'open' ? true : false);
    const [typeDisabled, setTypeDisabled] = useState(props.type === 'controleur' || props.type === 'wagenpark_en_controleur' ? false : true);
    const [correctieDisabled, setCorrectieDisabled] = useState(props.type === 'controleur' || props.type === 'wagenpark_en_controleur' ? false : true);
    const [feestdag, setFeestdag] = useState(false);

    //Function to fill fields with correct values on load
    const fillFields = () => {
        var items = [
            {
                begintijd: props.dayInfo.begintijd,
                eindtijd: props.dayInfo.eindtijd,
                pauze: props.dayInfo.pauze,
                diensturen: props.dayInfo.diensturen,
                urentype: {
                    id: props.dayInfo.urentype ? props.dayInfo.urentype.id : 1,
                    vul_diensturen: props.dayInfo.urentype ? props.dayInfo.urentype.vul_diensturen : false,
                    toelichting: props.dayInfo.urentype && props.dayInfo.urentype.toelichting ? props.dayInfo.urentype.toelichting : '',
                }
            }
        ];
        if (props.dayInfo.overuren) {
            props.dayInfo.overuren.map((item, index) =>  {
                const objOveruren = {
                    begintijd: item.begintijd_extra,
                    eindtijd: item.eindtijd_extra,
                    pauze: item.pauze_extra,
                    diensturen: item.diensturen_extra,
                    urentype: {
                        id: item.urentype ? item.urentype.id : 1
                    }
                };
                items.push(objOveruren);
            })
        }
        setUren(items);
        setStoringsdienst(props.dayInfo.storingsdienst);
        setKilometers(props.dayInfo.kilometers);
        setOpmerking(props.dayInfo.opmerking);
        setCorrectie(props.dayInfo.correctie);
        setFeestdag(props.dayInfo.feestdag);

        if (props.type === 'controleur' || props.type === 'wagenpark_en_controleur') {
            setDisabled(true);
        } else {
            const arrayDisable = [
                'deels_ingestuurd',
                'deels_goedgekeurd',
                'deels_goedgekeurd_def',
            ];
            setDisabled(props.status === 'ingestuurd' || props.status === 'goedgekeurd' || props.status === 'goedgekeurd_def' || (props.currentMonth === monthNow && arrayDisable.includes(props.status)) ? true : false);
            setTypeDisabled(props.status === 'ingestuurd' || props.status === 'goedgekeurd' || props.status === 'goedgekeurd_def' || (props.currentMonth === monthNow && arrayDisable.includes(props.status)) ? true : false);
        }

        if (props.status === 'goedgekeurd' && props.medewerker.id !== props.registraties.medewerker.controleur_twee_uren.id) {
            //setCorrectieDisabled(true);
        }
        if (props.status === 'goedgekeurd_def' && props.medewerker.id === props.registraties.medewerker.controleur_twee_uren.id) {
            setCorrectieDisabled(true);
        }
        if (props.currentMonth === monthNow && props.status === 'deels_goedgekeurd' && props.medewerker.id !== props.registraties.medewerker.controleur_twee_uren.id) {
            setCorrectieDisabled(true);
        }
        if (props.currentMonth === monthNow && props.status === 'deels_goedgekeurd_def') {
            setCorrectieDisabled(true);
        }
        if (props.currentMonth === monthNow && props.status === 'goedgekeurd' && props.registraties.medewerker.loonperiode === 'per_maand' && props.medewerker.id === props.registraties.medewerker.controleur_twee_uren.id) {
            //setCorrectieDisabled(true);
        }
    }

    //Function to handle change time field
    const handleChange = (index, field, value, vul_diensturen, toelichting) => {
        if (!value) {
            value = '00:00';
        }
        if (field === 'urentype') {
            value = {
                id: Number(value),
                vul_diensturen: JSON.parse(vul_diensturen),
                toelichting: toelichting
            };
        }
        let items = [...uren];
        let item = {...items[index]};
        item[field] = value;
        items[index] = item;
        
        if (items[index].begintijd && items[index].eindtijd && items[index].pauze) {
            var startDate = props.dayInfo.datum + ' ' + items[index].begintijd;
            var startDate = new Date(startDate.replace(' ', 'T'));
            var eindtijd = items[index].eindtijd;
            items[index].eindtijd === '00:00' && items[index].begintijd !== '00:00' ? eindtijd = '24:00' : eindtijd = items[index].eindtijd;
            var endDate = props.dayInfo.datum + ' ' + eindtijd;
            endDate = new Date(endDate.replace(' ', 'T'));
            const startTillEnd = getTimeFromSeconds((endDate.getTime() - startDate.getTime()) / 1000);
            var dateStartTillEnd = props.dayInfo.datum + ' ' + startTillEnd;
            dateStartTillEnd = new Date(dateStartTillEnd.replace(' ', 'T'));
            var pauze = props.dayInfo.datum + ' ' + items[index].pauze;
            pauze = new Date(pauze.replace(' ', 'T'));
            const workedHours = getTimeFromSeconds((dateStartTillEnd.getTime() - pauze.getTime()) / 1000);
            let item = {...items[index]};
            if (vul_diensturen === 'true' && index === 0) {
                item.diensturen = props.dayInfo.uren_volgens_rooster ? props.dayInfo.uren_volgens_rooster : workedHours;
            } else {
                item.diensturen = workedHours;
            }
            items[index] = item;
            setUren(items);
            let registrations = result;
            if (index === 0) {
                registrations[props.day].begintijd = items[0].begintijd;
                registrations[props.day].eindtijd = items[0].eindtijd;
                registrations[props.day].pauze = items[0].pauze;
                registrations[props.day].diensturen = items[0].diensturen;
                registrations[props.day].urentype.id = items[0].urentype.id;
                registrations[props.day].urentype.vul_diensturen = items[0].urentype.vul_diensturen;
                registrations[props.day].urentype.toelichting = items[0].urentype.toelichting;
            }
            const overuren = items.slice(1);
            const newOveruren = [];

            overuren.map((item, index) =>  {
                const obj = {
                    begintijd_extra: item.begintijd,
                    eindtijd_extra: item.eindtijd,
                    pauze_extra: item.pauze,
                    diensturen_extra: item.diensturen,
                    urentype: {
                        id: item.urentype.id
                    }
                };
                newOveruren.push(obj);
            })
            registrations[props.day].overuren = newOveruren;
        } else {
            let item = {...items[index]};
            item[field] = value;
            items[index] = item;
            setUren(items);
        }
        props.handleTotalHours(props.registraties);
    }

    //Function to handle change in correctie field
    const handleCorrectie = (value) => {
        if (!value) {
            value = '00:00';
        }
        setCorrectie(value);
        let registrations = result;
        registrations[props.day].correctie = value;
        props.handleTotalHours(registrations);
    }

    const handleKilometers = (value) => {
        setKilometers(Math.floor(value));
        let registrations = result;
        registrations[props.day].kilometers = Math.floor(value);
    }

    //Function to handle change in opmerking field
    const handleOpmerking = (value) => {
        setOpmerking(value);
        let registrations = result;
        registrations[props.day].opmerking = value;
    }

    const handleStoringsdienst = (value) => {
        if (value === 'true') {
            var booleanStoring = true;
        } else {
            var booleanStoring = false;
        }
        setStoringsdienst(booleanStoring);
        let registrations = result;
        registrations[props.day].storingsdienst = booleanStoring;
    }

    //Function to add new row to day
    const addRow = () => {
        //Check if first row has values and return if false
        if (uren[uren.length - 1].diensturen === '00:00' || uren[uren.length - 1].diensturen.includes('NaN')) {
            props.notifyError('Vul eerst de regel goed in voordat je een nieuwe regel toe kunt voegen.');
            return;
        }
        const row = {
            begintijd: '00:00',
            eindtijd: '00:00',
            pauze: '00:00',
            diensturen: '00:00',
            urentype: {
                id: 1
            }
        }
        let items = [...uren];
        items.push(row);
        setUren(items);
        let registrations = result;
        registrations[props.day].overuren.push(row);
    }

    //Function to remove row from day
    const removeRow = () => {
        let items = [...uren];
        items.pop();
        setUren(items);
        let registrations = result;
        const overuren = registrations[props.day].overuren;
        overuren.pop();
        registrations[props.day].overuren = overuren;
        props.handleTotalHours(props.registraties);
    }

    const handleInputFocus = (e) => {
        e.target.select();
    };

    //Run function fillFields on load
    useEffect(() => {
        fillFields();
    }, [props]);

    return (
        <div className={'day' + (props.day === 'zaterdag' || props.day === 'zondag' ? ' weekend' : '')}>
            <div className="day-content">
                <div className="day-info">
                    <p>{props.day}<br/>{(props.type === 'controleur' || props.type === 'wagenpark_en_controleur') && props.dayInfo.uren_volgens_rooster ? <span className="rooster-uren">{'Roosteruren: ' + props.dayInfo.uren_volgens_rooster}</span> : null}</p>
                    <div>
                    <span>{moment(props.dayInfo.datum).format('DD-MM-YYYY')}</span>
                    {
                        (props.medewerker.storingsdienst_invullen && (props.type !== 'controleur' || props.type !== 'wagenpark_en_controleur')) || (props.registraties.medewerker.storingsdienst_invullen && (props.type === 'controleur' || props.type === 'wagenpark_en_controleur')) ?
                        <>
                            <br/>
                            <input type="checkbox" id={props.day} name={props.day} value={storingsdienst ? false : true} checked={storingsdienst} disabled={disabled} onChange={(e) => handleStoringsdienst(e.target.value)} />
                            <label for={props.day}> Storingsdienst</label>
                        </>
                        :
                        null
                    }
                    </div>
                    
                    {/* Show feestdag image if true */}
                    {
                        feestdag ?
                        <>
                        <img src={feestdagImage} alt="Feestdag" id={'feestdag-' + props.day} className="feestdag-icon" />
                        <Tooltip
                            anchorId={'feestdag-' + props.day}
                            place="top"
                            content="Feestdag"
                        />
                        </>
                        :
                        null
                    }
                </div>
                
                <div className="time-fields">
                    {
                        uren.map((item, index) =>  {
                            var pauzeValue = 0;
                            if (item.pauze) {
                                pauzeValue = Number(item.pauze.substring(0, 2));
                            }
                            return(
                                <div key={index} className={'row'}>
                                    <div className="field">
                                        <label>Begintijd</label>
                                        <InputMask pattern="[0-9]*" inputMode="numeric" mask="99:99" maskPlaceholder="0" onChange={(e) => handleChange(index, 'begintijd', e.target.value)} value={item.begintijd ? item.begintijd : ''} disabled={props.medewerker.gebruiker.username === 'rober@vsdv.nl' ? false : props.type !== 'controleur' || props.type !== 'wagenpark_en_controleur' ? disabled : false} onFocus={(e) => e.target.select()} />
                                        {/* <input type="time" placeholder="00:00" step="60" value={item.begintijd ? item.begintijd : ''} onChange={(e) => handleChange(index, 'begintijd', e.target.value)} disabled={disabled} /> */}
                                    </div>
                                    <div className="field">
                                        <label>Eindtijd</label>
                                        <InputMask pattern="[0-9]*" inputMode="numeric" mask="99:99" maskPlaceholder="0" onChange={(e) => handleChange(index, 'eindtijd', e.target.value)} value={item.eindtijd ? item.eindtijd : ''} disabled={props.medewerker.gebruiker.username === 'rober@vsdv.nl' ? false : props.type !== 'controleur' || props.type !== 'wagenpark_en_controleur' ? disabled : false} onFocus={(e) => e.target.select()} />
                                        {/* <input type="time" placeholder="00:00" step="60" value={item.eindtijd ? formatTime(item.eindtijd) : ''} onChange={(e) => handleChange(index, 'eindtijd', e.target.value)} disabled={disabled} /> */}
                                        {
                                            item.diensturen && item.diensturen.includes("NaN") && item.eindtijd !== '00:00' ?
                                            <div className="validation-message">Eindtijd moet hoger zijn dan begintijd {item.diensturen} {item.eindtijd}</div>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="field">
                                        <label>Pauze</label>
                                        <InputMask pattern="[0-9]*" inputMode="numeric" mask="99:99" maskPlaceholder="0" onChange={(e) => handleChange(index, 'pauze', e.target.value)} value={item.pauze ? item.pauze : ''} disabled={props.medewerker.gebruiker.username === 'rober@vsdv.nl' ? false : props.type !== 'controleur' || props.type !== 'wagenpark_en_controleur' ? disabled : false} onFocus={(e) => e.target.select()} />
                                        {/* <input type="time" placeholder="00:00" step="60" value={item.pauze ? formatTime(item.pauze) : ''} onChange={(e) => handleChange(index, 'pauze', e.target.value)} disabled={disabled} /> */}
                                        {
                                            pauzeValue > props.longBreak ?
                                            <div className="validation-message">Lange pauze</div>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="field">
                                        <label>Diensturen</label>
                                        <InputMask pattern="[0-9]*" inputMode="numeric" mask="99:99" maskPlaceholder="0" value={item.diensturen ? item.diensturen : ''} disabled className="disabled" />
                                        {/* <input type="time" placeholder="00:00" step="60" disabled className="disabled" value={item.diensturen ? item.diensturen : ''} /> */}
                                        {
                                            (item.diensturen && (item.diensturen.includes("NaN") || item.diensturen.includes("-"))) && item.begintijd !== '00:00' && item.eindtijd !== '00:00' && item.pauze !== '00:00' ?
                                            <div className="validation-message">Er is meer pauzetijd ingevuld dan werktijd</div>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="field hourtype">
                                        <label>Type</label>
                                        {
                                            props.urenTypes.length > 0 ?
                                            <>
                                            <select value={item.urentype.id} onChange={(e) => handleChange(index, 'urentype',  e.target.value, e.target.options[e.target.selectedIndex].getAttribute('vul_diensturen'), e.target.options[e.target.selectedIndex].getAttribute('toelichting'))} disabled={typeDisabled}>
                                                {
                                                    props.urenTypes.map((option, index) =>  {
                                                        if (option.key === 'ziek_een' && props.medewerker.divisie.nummer !== 88 && props.type !== 'controleur') {
                                                            return false;
                                                        }
                                                        return <option key={index} value={option.id} vul_diensturen={option.vul_diensturen.toString()} toelichting={option.toelichting}>{option.naam}</option>
                                                    })
                                                }
                                            </select>
                                            {
                                                item.urentype.vul_diensturen && item.urentype.toelichting && item.diensturen !== '00:00' && (props.type !== 'controleur' || props.type !== 'wagenpark_en_controleur') ?
                                                <div className="validation-message feestdag">{item.urentype.toelichting}</div>
                                                :
                                                null
                                            }
                                            {
                                                
                                            }
                                            </>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className={'extra ' + (props.type ? props.type : props.status)}>
                        {
                            (props.medewerker.kilometers_invullen && (props.type !== 'controleur' || props.type !== 'wagenpark_en_controleur')) || (props.registraties.medewerker.kilometers_invullen && (props.type === 'controleur' || props.type !== 'wagenpark_en_controleur')) ?
                            <div className="field" style={{marginBottom:10}}>
                                <label>Kilometers</label>
                                <input type="text" pattern="[0-9]*" onFocus={handleInputFocus} onChange={(e) => handleKilometers(e.target.value)} value={kilometers ? kilometers : 0} disabled={disabled} />
                            </div>
                            :
                            null
                        }
                        {
                            props.type === 'controleur' ?
                            <div className="field">
                                <label>Correctie</label>
                                <InputMask pattern="[0-9]*" inputMode="numeric" mask="99:99" maskPlaceholder="0" className={correctie !== '00:00' ? 'corrected' : ''} onChange={(e) => handleCorrectie(e.target.value)} value={correctie ? correctie : ''} disabled={correctieDisabled} onFocus={(e) => e.target.select()} />
                                {/* <input type="time" placeholder="00:00" step="60" className="disabled" disabled={correctieDisabled} onChange={(e) => handleCorrectie(e.target.value)} value={correctie ? correctie : ''} /> */}
                            </div>
                            :
                            <>
                            {
                                props.status === 'goedgekeurd' || props.status === 'goedgekeurd_def' ?
                                <div className="field">
                                    <label>Correctie</label>
                                    <InputMask pattern="[0-9]*" inputMode="numeric" mask="99:99" maskPlaceholder="0" className={correctie !== '00:00' ? 'corrected' : ''} value={correctie ? correctie : ''} onChange={(e) => handleCorrectie(e.target.value)} disabled={correctieDisabled} onFocus={(e) => e.target.select()} />
                                    {/* <input type="time" placeholder="00:00" step="60" className="disabled" disabled value={correctie ? correctie : ''} /> */}
                                </div>
                                :
                                null
                            }
                            </>
                        }
                        {
                            !opmerking && disabled ?
                            <div></div>
                            :
                            <>
                            <textarea placeholder="Opmerking" value={opmerking ? opmerking : ''} onChange={(e) => handleOpmerking(e.target.value)} disabled={disabled}></textarea>
                            </>
                        }
                    
                    </div>
                    {
                        !disabled ?
                        <div className={"row-actions " + (uren.length > 1 ? "multiple" : "")}>
                            {
                                uren.length > 1 ?
                                <>
                                <button id={'remove-' + props.day} onClick={removeRow} className="remove"><Icon.DashCircleFill /></button>
                                <Tooltip
                                    anchorId={'remove-' + props.day}
                                    place="bottom"
                                    content="Rij verwijderen"
                                />
                                </>
                                :
                                null
                            }
                            {
                                uren.length < props.maxRows ?
                                <>
                                <button id={'add-' + props.day} onClick={addRow} className="add"><Icon.PlusCircleFill /></button>
                                <Tooltip
                                    anchorId={'add-' + props.day}
                                    place="bottom"
                                    content="Rij toevoegen"
                                />
                                </>
                                :
                                null
                            }
                        </div>
                        :
                        null
                    }
                    
                </div>
            </div>
        </div>
    );
};
export default Registratiedag;