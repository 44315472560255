//3rd party
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import Skeleton from 'react-loading-skeleton';
import { TailSpin } from  'react-loader-spinner';
import * as Icon from 'react-bootstrap-icons';
import { saveAs } from "file-saver";
import { Tooltip } from 'react-tooltip';

//styles
import './declaratiesBlock.css';
import 'react-tooltip/dist/react-tooltip.css';

const DeclaratiesBlock = (props) => {

    //Declare variables
    const environment = process.env.REACT_APP_APP_URL;
    const title = props.title;
    const type = props.type;
    const marginTop = props.marginTop;
    const declaraties = props.declaraties;
    const loadingDeclaraties = props.loadingDeclaraties;
    const deleteDeclaratie = props.deleteDeclaratie;
    const resendDeclaratie = props.resendDeclaratie;
    const isDeleting = props.isDeleting;
    const fileMedewerker = props.fileMedewerker;
    const nothingFound = props.nothingFound;

    return (
        <div className="content" style={{ marginTop: marginTop }}>
                <div className="block-header"><h3>{title}</h3></div>
                {
                    loadingDeclaraties ?
                    <div className="loaders">
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                    </div>
                    :
                    <>
                    {
                        declaraties.length > 0 ?
                        <>
                            {
                                declaraties.map((item, index) =>  {
                                    var bedrag = Number(item.bedrag).toFixed(2);
                                    bedrag = bedrag.replace(".", ",");
                                    return (
                                    <div key={index} className="expense-item">
                                        <div className="title">
                                            {
                                                type !== 'incorporated' ?
                                                <div className="declaratie-actions">
                                                <>
                                                <span className="delete" id={type + '-' + index + '-delete'} onClick={() => { deleteDeclaratie(item.id) }}><Icon.Trash3 /></span>
                                                <Tooltip
                                                    anchorId={type + '-' + index + '-delete'}
                                                    place="bottom"
                                                    content="Declaratie verwijderen"
                                                />
                                                </>
                                                {
                                                    type === 'rejected' ?
                                                    <>
                                                    <span className="resend" id={type + '-' + index + '-refresh'} onClick={() => { resendDeclaratie(item) }}><Icon.ArrowClockwise /></span>
                                                    <Tooltip
                                                        anchorId={type + '-' + index + '-refresh'}
                                                        place="bottom"
                                                        content="Declaratie opnieuw insturen"
                                                    />
                                                    </>
                                                    :
                                                    null
                                                }
                                                
                                                </div>
                                                :
                                                null
                                            }
                                            <span className="price">&euro; {bedrag}</span>
                                            <span className="name">{item.omschrijving}{type === 'rejected' ? <><br/><span className="reason">{item.reden_afgekeurd}</span></> : null}</span>
                                        </div>
                                        <div className="meta">
                                            <div><span className="date">{moment(item.datum).format('DD-MM-YYYY')}</span></div>
                                            {
                                                item.kilometer_declaratie === false ?
                                                <div><span className="download" onClick={() => { saveAs(environment + item.bestand.url, 'declaratie-' + fileMedewerker + '-' + moment(item.datum).format('DD-MM-YYYY')) }}><Icon.Download /><span>Download</span></span></div>
                                                :
                                                <div><span>Geen foto/bon</span></div>
                                            }
                                            <div><span className="date"><span className="label">{item.status === 'ingestuurd' ? 'Ingestuurd op:' : 'Bijgewerkt op:'}</span><br/>{moment(item.updatedAt).format('DD-MM-YYYY')}</span></div>
                                        </div>
                                    </div>
                                    )
                                })
                            }
                        </>
                        :
                        <p className="not-found">{nothingFound}</p>
                    }
                    </>
                }
                {
                    isDeleting ?
                    <div className="loader-deleting">
                        <TailSpin
                            height="30"
                            width="30"
                            color="#001c43"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>
                    :
                    null
                }
        </div>
    );
};
export default DeclaratiesBlock;