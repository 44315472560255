import { useState } from 'react';

//3rd party
import * as Icon from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';

//styles
import './schadeTijdlijn.css';
import 'react-loading-skeleton/dist/skeleton.css';

const SchadeTijdlijn = (props) => {

    const logs = props.schade.log;
    const [openAudit, setOpenAudit] = useState(false);

    return (
        <>
        {
            props.schade ?
            <div className={'table-audit' + (logs.length > 6 ? ' collapsed' : '') + (openAudit ? ' open' : '')}>
                <table id="audit">
                    <thead>
                        <tr>
                            <th>Log</th>
                            <th>Door</th>
                            <th>Datum en tijd</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            logs ?
                            logs.map((item, index) =>  {
                                return <tr key={index}>
                                    <td style={{maxWidth:250}}>{item.log_omschrijving}</td>
                                    <td>{item.medewerker.voornaam + ' ' + item.medewerker.achternaam}</td>
                                    <td>{moment(item.datum_tijd).format('DD-MM-YYYY HH:mm')}</td>
                                </tr>
                            })
                            :
                            <p>Er is geen tijdlijn</p>
                        }
                    </tbody>
                </table>
                {
                    logs.length > 6 ?
                    <div className={'faded ' + (openAudit ? 'hide' : '')}>
                        <button className="show-more" onClick={() => setOpenAudit(openAudit ? false : true)}>{openAudit ? <span>Bekijk minder</span> : <span>Bekijk meer</span>} {openAudit ? <Icon.ArrowUpCircle /> : <Icon.ArrowDownCircle />}</button>
                    </div>
                    :
                    null
                }
            </div>
            :
            <>
            <Skeleton height={20} style={{marginBottom:10}} />
            <Skeleton height={20} style={{marginBottom:10}} />
            <Skeleton height={20} style={{marginBottom:10}} />
            <Skeleton height={20} style={{marginBottom:10}} />
            </>
        }
        </>
    );
};
export default SchadeTijdlijn;