import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";

//Helper functions
import { getSettings, notifyError, notifySuccess, extractPlace, handleDownload } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import * as Icon from 'react-bootstrap-icons';
import toast, { Toaster } from 'react-hot-toast';
import { Tooltip } from 'react-tooltip';
import Select from 'react-select';
import Autocomplete from "react-google-autocomplete";
import { ThreeDots } from  'react-loader-spinner';
import Slider from "react-slick";

//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';
import SchadeGegevens from '../../../components/schade/schadeGegevens';
import SchadeBedragen from '../../../components/schade/schadeBedragen';
import SchadeInformatie from '../../../components/schade/schadeInformatie';
import SchadeDocumenten from '../../../components/schade/schadeDocumenten';
import SchadeFotos from '../../../components/schade/schadeFotos';
import SchadeTijdlijn from '../../../components/schade/schadeTijdlijn';
import SchadeCommunicatie from '../../../components/schade/schadeCommunicatie';
import MyMapComponent from '../../../components/maps/googleMaps';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';
import svzArtwork from '../../../assets/images/artwork-svz.svg';

//styles
import './schade.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-tooltip/dist/react-tooltip.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Schade = () => {

    //Set variables
    const { id } = useParams();
    const navigate = useNavigate();
    const environment = process.env.REACT_APP_APP_URL;
    const loggedIn = localStorage.getItem('ingelogd');
    const medewerker = JSON.parse(localStorage.getItem('medewerker'));
    const [isLoading, setIsLoading] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [showDocument, setShowDocument] = useState(false);
    const [documentTitle, setDocumentTitle] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [iframePdf, setIframePdf] = useState('');
    const [showSlider, setShowSlider] = useState(false);
    const [schade, setSchade] = useState('');
    const documentInputRef = useRef(null);
    const [schadeDocument, setSchadeDocument] = useState({
        naam: '',
        value: ''
    });
    const [statussen, setStatussen] = useState([]);
    const [planners, setPlanners] = useState([]);
    const [chauffeurs, setChauffeurs] = useState([]);
    const [charters, setCharters] = useState([]);
    const [kentekens, setKentekens] = useState([]);
    const [kentekensTranspas, setKentekensTranspas] = useState([]);
    const [werkmaterieel, setWerkmaterieel] = useState([]);
    const [kentekensWerkmaterieel, setKentekensWerkmaterieel] = useState([]);
    const [trailers, setTrailers] = useState([
        {
            value: 2,
            label: '12-AAAA-C'
        }
    ]);
    const [soortenVervoer, setSoortenVervoer] = useState([]);
    const [schadeTypes, setSchadeTypes] = useState([]);

    const [changeStatus, setChangeStatus] = useState(false);
    const [documents, setDocuments] = useState([]);
    const ImageInputRef = useRef(null);
    const [imageType, setImageType] = useState('0');
    const [images, setImages] = useState();
    const [log, setLog] = useState('');
    const [bericht, setBericht] = useState('');
    const [sendMailBericht, setSendMailBericht] = useState(true);
    const [status, setStatus] = useState({
        value: '',
        label: ''
    });
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    const sliderRef = useRef();

    //Variables for changing schade
    const [gegevensValid, setGegevensValid] = useState(false);
    const [editGegevens, setEditGegevens] = useState(false);
    const [gegevens, setGegevens] = useState({
        kenmerk: {
            value: '',
            required: true
        },
        kenmerk_tvm: {
            value: '',
            required: false
        },
        nota: {
            value: '',
            required: false
        },
        charterschade: {
            value: false,
            required: false
        },
        datum: {
            value: '',
            required: true
        },
        chauffeur_medewerker: {
            value: '',
            required: true
        },
        charter: {
            value: '',
            required: false
        },
        planner_melder: {
            value: '',
            required: true
        },
        tegenpartij: {
            value: '',
            required: false
        },
        kenteken: {
            value: '',
            required: true
        },
        trailer: {
            value: '',
            required: false
        },
        schadebedrag: {
            value: '',
            required: false
        },
        schadebedrag_tegenpartij: {
            value: '',
            required: false
        },
        eigen_risico: {
            value: '',
            required: false
        },
        type_schade: {
            value: '',
            required: true
        },
        schuldschade: {
            value: false,
            required: false
        },
        verhaal_eigen: {
            value: '',
            required: true
        },
        betaald_door_tvm: {
            value: '',
            required: false
        },
        wa_er: {
            value: '',
            required: false
        }
    });

    const [schadeInfoValid, setSchadeInfoValid] = useState(false);
    const [editSchadeInfo, setEditSchadeInfo] = useState(false);
    const [schadeInfo, setSchadeInfo] = useState({
        land: {
            value: '',
            required: true
        },
        plaats: {
            value: '',
            required: true
        },
        schadeomschrijving: {
            value: '',
            required: true
        },
        schadeoorzaak: {
            value: '',
            required: true
        },
        actie_richting_chauffeur: {
            value: '',
            required: false
        },
        soort_vervoer: {
            value: '',
            required: true
        }
    });
    const [getuigenValid, setGetuigenValid] = useState(false);
    const [editGetuigen, setEditGetuigen] = useState(false);
    const [getuigen, setGetuigen] = useState({
        value: [{
            naam: '',
            adres: '',
            telefoonnummer: ''
        }],
        required: false
    });
    const [schadeFotosSlides, setSchadeFotosSlides] = useState([]);
    const goToSlide = (id) => {
        const index = schadeFotosSlides.findIndex(item => item.id === id);
        if (sliderRef.current) {
          sliderRef.current.slickGoTo(index);
        }
    };

    const fetchPdf = async (pdfFileUrl) => {
        if (!pdfFileUrl) {
            return setIframePdf('');
        }
        const response = await fetch(pdfFileUrl);
        const data = await response.blob();
        setIframePdf(URL.createObjectURL(data));
    };

    const getFileContents = async (folder, subfolder, filename) => {
        if (!filename) {
            return setIframePdf('');
        }
        api.get('/schades/get-file/' + folder + '/' + subfolder + '/' + filename, { responseType: 'blob' })
          .then(response => {
            const url = URL.createObjectURL(response.data);
            setIframePdf(url);
          })
          .catch(error => {
            console.error('There was a problem with the axios request:', error);
          });
    }

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const getSchade = async (id) => {
        setIsLoading(true);
        await delay(500);
        try {
            const response = await api.get('/schades/' + id);
            if (response.data) {
                setSchade(response.data);
                setDocuments(response.data.documenten);
                //Set schadefotos slider
                const schadeFotosArray = [];
                if (response.data.fotos_eigen_schade) {
                    response.data.fotos_eigen_schade.map((item, index) =>  {
                        const obj = { id: item.id, label: 'Eigen schade ' + (index + 1), image: environment + '' + item.url }
                        schadeFotosArray.push(obj);
                    })
                }
                if (response.data.fotos_omgeving) {
                    response.data.fotos_omgeving.map((item, index) =>  {
                        const obj = { id: item.id, label: 'Omgeving ' + (index + 1), image: environment + '' + item.url }
                        schadeFotosArray.push(obj);
                    })
                }
                if (response.data.fotos_schade_tegenpartij) {
                    response.data.fotos_schade_tegenpartij.map((item, index) =>  {
                        const obj = { id: item.id, label: 'Tegenpartij ' + (index + 1), image: environment + '' + item.url }
                        schadeFotosArray.push(obj);
                    })
                }
                setSchadeFotosSlides(schadeFotosArray);
                var newStatus = {...status};
                newStatus.value = response.data.status.id;
                newStatus.label = response.data.status.naam;
                setStatus(newStatus);
                setChangeStatus(false);

                //Set values for change schade form
                const schade = response.data;
                const currentGegevens = {...gegevens};
                currentGegevens.kenmerk.value = schade.kenmerk;
                currentGegevens.kenmerk_tvm.value = schade.kenmerk_tvm ? schade.kenmerk_tvm : '';
                currentGegevens.nota.value = schade.nota ? schade.nota : '';
                currentGegevens.datum.value = schade.datum;
                if (schade.chauffeur_medewerker) {
                    currentGegevens.chauffeur_medewerker.value = { value: schade.chauffeur_medewerker.id, label: schade.chauffeur_medewerker.naam};
                } else {
                    currentGegevens.charter.value = { value: schade.charter.id, label: schade.charter.naam};
                    currentGegevens.charter.required = true;
                    currentGegevens.chauffeur_medewerker.required = false;
                }
                currentGegevens.planner_melder.value = { value: schade.planner_melder.id, label: schade.planner_melder.naam};
                currentGegevens.kenteken.value = { value: schade.kenteken, label: schade.kenteken };
                currentGegevens.trailer.value = { value: schade.kenteken_trailer_aanhanger, label: schade.kenteken_trailer_aanhanger }
                currentGegevens.type_schade.value = { value: schade.schadetype.id, label: schade.schadetype.naam};
                currentGegevens.schuldschade.value = schade.schuldschade;
                currentGegevens.verhaal_eigen.value = schade.verhaal_eigen;
                //currentGegevens.kenteken.value = { value: schade.trailer.id, label: schade.trailer.kenteken};
                currentGegevens.tegenpartij.value = schade.tegenpartij;
                currentGegevens.schadebedrag.value = schade.schadebedrag;
                currentGegevens.schadebedrag_tegenpartij.value = schade.schadebedrag_tegenpartij;
                currentGegevens.eigen_risico.value = schade.eigen_risico;
                currentGegevens.betaald_door_tvm.value = schade.betaald_door_tvm;
                currentGegevens.wa_er.value = schade.wa_er;
                setGegevens(currentGegevens);

                //Set values for schadeinfo
                const currentSchadeInfo = {...schadeInfo};
                currentSchadeInfo.land.value = schade.land;
                currentSchadeInfo.plaats.value = {
                    naam: schade.plaats.naam,
                    lat: schade.plaats.lat,
                    lng: schade.plaats.lng
                };
                currentSchadeInfo.schadeomschrijving.value = schade.schadeomschrijving;
                currentSchadeInfo.schadeoorzaak.value = schade.schadeoorzaak;
                currentSchadeInfo.actie_richting_chauffeur.value = schade.actie_richting_chauffeur;
                currentSchadeInfo.soort_vervoer.value = { value: schade.soort_vervoer.id, label: schade.soort_vervoer.naam};
                setSchadeInfo(currentSchadeInfo);

                //Set values for getuigen
                const currentGetuigen = {...getuigen};
                currentGetuigen.value = schade.getuigen;
                setGetuigen(currentGetuigen);

                setGegevensValid(true);
                setSchadeInfoValid(true);
                setGetuigenValid(true);

                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
            if (!error.response) {
                //return navigate('/offline');
            }
        }
    }

    const updateStatus = async () => {
        if (status.label === 'Afgewerkt TVM' && !schade.kenmerk_tvm) {
            return notifyError('Er is nog geen kenmerk TVM bekend');
        }
        setIsLoading(true);
        try {
            const response = await toast.promise(
                api.post('/schades/status/' + schade.id, {
                    data: {
                        status: status,
                        vorige_status: schade.status.id
                    }
                }),
                {
                    loading: 'Opslaan...',
                    success: <b>Schadestatus is bijgewerkt</b>,
                    error: <b>Er is iets misgegaan met het opslaan. Probeer het later nog eens of neem contact op met ICT.</b>,
                }
            );
            if (response.data) {
                setSchade(response.data);
                setIsLoading(false);
                setChangeStatus(false);
            }
        } catch (error) {}
    }

    const updateSchade = async (form) => {
        setIsLoading(true);
        if (form === 'gegevens') {
            var data = {
                datum: gegevens.datum.value,
                kenmerk: gegevens.kenmerk.value,
                kenmerk_tvm: gegevens.kenmerk_tvm.value,
                nota: gegevens.nota.value,
                chauffeur_medewerker: { id: gegevens.chauffeur_medewerker.value ? gegevens.chauffeur_medewerker.value.value : '' },
                planner_melder: { id: gegevens.planner_melder.value.value },
                charter: { id: gegevens.charter.value ? gegevens.charter.value.value : '' },
                tegenpartij: gegevens.tegenpartij.value,
                kenteken: gegevens.kenteken.value.value,
                bedrijf: gegevens.kenteken.value.companyName ? gegevens.kenteken.value.companyName : '',
                kenteken_trailer_aanhanger: gegevens.trailer.value ? gegevens.trailer.value.value : '',
                schadetype: { id: gegevens.type_schade.value.value },
                schuldschade: gegevens.schuldschade.value,
                verhaal_eigen: gegevens.verhaal_eigen.value,
                schadebedrag: gegevens.schadebedrag.value ? gegevens.schadebedrag.value : 0,
                schadebedrag_tegenpartij: gegevens.schadebedrag_tegenpartij.value ? gegevens.schadebedrag_tegenpartij.value : 0,
                eigen_risico: gegevens.eigen_risico.value ? gegevens.eigen_risico.value : 0,
                betaald_door_tvm: gegevens.betaald_door_tvm.value ? gegevens.betaald_door_tvm.value : 0,
                wa_er: gegevens.wa_er.value ? gegevens.wa_er.value : 0
            }
        }
        if (form === 'schadeinfo') {
            var data = {
                kenmerk: gegevens.kenmerk.value,
                land: schadeInfo.land.value,
                plaats: schadeInfo.plaats.value,
                schadeomschrijving: schadeInfo.schadeomschrijving.value,
                schadeoorzaak: schadeInfo.schadeoorzaak.value,
                actie_richting_chauffeur: schadeInfo.actie_richting_chauffeur.value,
                soort_vervoer: { id: schadeInfo.soort_vervoer.value.value },
            }
        }
        if (form === 'getuigen') {
            var data = {
                kenmerk: gegevens.kenmerk.value,
                getuigen: getuigen.value
            }
        }
        try {
            const response = await toast.promise(
                api.post('/schades/update/' + schade.id, data),
                {
                    loading: 'Opslaan...',
                    success: <b>Schadedossier is bijgewerkt</b>,
                    error: (err) => `${err.response.data.error.message}`
                }
            );
            if (response.data) {
                setSchade(response.data);
                setIsLoading(false);
                setShowForm(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }

    const getOpties = async () => {
        try {
            const response = await api.get('/schades/opties');
            if (response.data) {
                setStatussen(response.data.statussen);
                setPlanners(response.data.planners);
                setChauffeurs(response.data.chauffeurs);
                setCharters(response.data.charters);
                setSoortenVervoer(response.data.soorten_vervoer);
                setSchadeTypes(response.data.schadetypes);
            }
        } catch (error) {
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                  break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }

    const getKentekensTranspas = async () => {
        try {
            const response = await api.get('/schades/kentekens');
            if (response.data) {
                const organizedData = Object.values(response.data).reduce((result, item) => {
                    if (!result.some((category) => category.label === item.type)) {
                      result.push({ label: item.type, options: [] });
                    }
                    result.find((category) => category.label === item.type).options.push({
                        value: item.value,
                        label: <div><span>{item.label}</span><span style={{ color: '#919191', position: 'absolute', right: 5 }}>{item.vlootnummer}</span></div>,
                        searchValue: item.value + ' ' + item.vlootnummer,
                        companyId: item.user_company_id,
                        companyName: item.company_name
                    });
                    return result;
                }, []);
                setKentekensTranspas(organizedData);
            }
        } catch (error) {
            if (!error.response) {
                return navigate('/offline');
            }
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                  break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }

    const assignDocument = async (id) => {
        try {
            const response = await api.post('/schades/upload-document/' + schade.id, {
                data: {
                    documentnaam: schadeDocument.naam,
                    document: {
                        id: id
                    }
                }
            });
            if (response.data) {
                const current = {...schade};
                current.documenten = response.data.documenten;
                setSchade(current);
                setSchadeDocument({
                    naam: '',
                    value: ''
                });
                documentInputRef.current.value = '';
                notifySuccess('Document aan dossier toegevoegd');
            }
        } catch (error) {
            
        }
    }

    const uploadDocument = async (event) => {
        event.preventDefault();
        var formData = new FormData();
        formData.append("files", schadeDocument.value);
        if (schadeDocument.value.size > 52428800) {
            return notifyError('De maximale bestandsgrootte van 50MB');
        }
        try {
            const response = await api.post('/upload', formData, {
                headers: { 
                    "Content-Type": "multipart/form-data"
                }
            });
            if (response.data) {
                assignDocument(response.data[0].id);
            }
        } catch (error) {
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                  break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }

    const assignDocumentFs = async (filename) => {
        try {
            const response = await api.post('/schades/upload-document/' + schade.id, {
                data: {
                    documentnaam: schadeDocument.naam,
                    pad: filename
                }
            });
            if (response.data) {
                const current = {...schade};
                current.documenten = response.data.documenten;
                setSchade(current);
                setSchadeDocument({
                    naam: '',
                    value: ''
                });
                documentInputRef.current.value = '';
                notifySuccess('Document aan dossier toegevoegd');
            }
        } catch (error) {
            
        }
    }

    const uploadDocumentFs = async (event) => {
        event.preventDefault();
        var formData = new FormData();
        formData.append("files", schadeDocument.value);
        if (schadeDocument.value.size > 52428800) {
            return notifyError('De maximale bestandsgrootte van 50MB');
        }
        try {
            const response = await api.post('/schades/upload-document-fs/' + schade.kenmerk, formData, {
                headers: { 
                    "Content-Type": "multipart/form-data"
                }
            });
            if (response.data) {
                const path = response.data.filepaths[0];
                const fileName = path.match(/[^\\]+$/)[0];
                assignDocumentFs(fileName);
            }
        } catch (error) {
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                  break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }


    const assignImages = async (images) => {
        try {
            const response = await api.post('/schades/upload-fotos/' + schade.id, {
                data: {
                    type: Number(imageType),
                    images: images
                }
            });
            if (response.data) {
                const current = {...schade};
                current.fotos_eigen_schade = response.data.fotos_eigen_schade;
                current.fotos_schade_tegenpartij = response.data.fotos_schade_tegenpartij;
                current.fotos_omgeving = response.data.fotos_omgeving;
                setSchade(current);
                setImages('');
                setImageType('0');
                ImageInputRef.current.value = '';
                notifySuccess('Foto\'s aan dossier toegevoegd');
            }
        } catch (error) {
            
        }
    }

    const uploadImages = async (event) => {
        event.preventDefault();
        var formData = new FormData();
        Object.keys(images).forEach((key) => {
            formData.append("files", images[key]);
        });
        try {
            const response = await api.post('/upload', formData, {
                headers: { 
                    "Content-Type": "multipart/form-data"
                }
            });
            if (response.data) {
                const arrayResult = [];
                response.data.map((item, index) =>  {
                    const obj = { id: item.id }
                    arrayResult.push(obj);
                })
                assignImages(arrayResult);
            }
        } catch (error) {
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                  break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }

    const deleteImage = async (key, index) => {
        const images = {...schade};
        images[key].splice(index, 1);
        const imageIds = images[key].map(obj => obj.id);
        try {
            const response = await api.post('/schades/delete-fotos/' + id, {
                data: {
                    imageIds: imageIds,
                    key: key
                }
            });
            if (response.data) {
                const current = {...schade};
                current[key] = response.data[key];
                setSchade(current);
                notifySuccess('Foto is verwijderd');
            }
        } catch (error) {
            
        }
    }

    const deleteDocument = async (index) => {
        const documents = {...schade};
        documents.documenten.splice(index, 1);
        try {
            const response = await api.post('/schades/delete-documenten/' + id, {
                data: {
                    documents: documents.documenten
                }
            });
            if (response.data) {
                const current = {...schade};
                current.documenten = response.data.documenten;
                setSchade(current);
                notifySuccess('Document is verwijderd');
            }
        } catch (error) {
            
        }
    }

    const submitLog = async (event) => {
        event.preventDefault();
        try {
            const response = await api.post('/schades/new-log/' + id, {
                data: {
                    log: log
                }
            });
            if (response.data) {
                const current = {...schade};
                current.log = response.data.log;
                setSchade(current);
                setLog('');
                notifySuccess('Item toegevoegd aan tijdlijn');
            }
        } catch (error) {
            
        }
    }

    const submitBericht = async (event) => {
        event.preventDefault();
        try {
            const response = await api.post('/schades/bericht/' + id, {
                data: {
                    bericht: bericht,
                    sendMail: sendMailBericht
                }
            });
            if (response.data) {
                const current = {...schade};
                current.communicatie = response.data.communicatie;
                setSchade(current);
                setBericht('');
            }
        } catch (error) {
            
        }
    }

    const archiveSchade = async () => {
        setIsLoading(true);
        await delay(500);
        try {
            const response = await api.post('/schades/archiveren/' + schade.id);
            if (response.data.kenmerk) {
                notifySuccess('Schade is gearchiveerd');
                await delay(500);
                navigate('/schades');
            }
        } catch (error) {}
    }

    //Handle form changes and check for validation
    const isFormValid = (obj) => {
        const requiredFields = Object.keys(obj).filter(key => obj[key].required);
        return requiredFields.every(key => obj[key].value !== '');
    }
    const handleChange = (section, field, value, required) => {
        if (section === 'gegevens') {
            const current = {...gegevens};
            current[field].value = value;
            setGegevens(current);
            setGegevensValid(isFormValid(current));
        }
        if (section === 'schade') {
            const current = {...schadeInfo};
            current[field].value = value;
            setSchadeInfo(current);
            setSchadeInfoValid(isFormValid(current));
        }
        if (section === 'document') {
            const current = {...schadeDocument};
            current[field] = value;
            setSchadeDocument(current);
        }
    }

    //Change checkbox value
    const changeSchuldChauffeur = () => {
        if (gegevens.schuldschade.value) {
            handleChange('gegevens', 'schuldschade', false);
        } else {
            handleChange('gegevens', 'schuldschade', true);
        }
    };

    const handleGetuigenChange = (field, value, index) => {
        const current = {...getuigen};
        current.value[index][field] = value;
        setGetuigen(current);
    }
    const handleGetuigen = (type) => {
        const current = {...getuigen};
        const obj = {
            naam: '',
            adres: '',
            telefoonnummer: ''
        }
        if (type === 'add') {
            current.value.push(obj);
        } else {
            current.value.pop();
        }
        setGetuigen(current);
    }


    //Check if user is authenticated, else redirect to login
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        if (medewerker.gebruiker.role.type !== 'schadebeheer' && medewerker.gebruiker.role.type !== 'super_admin') {
            navigate('/');
        }
        window.scrollTo(0, 0);
        getSchade(id);
        getOpties();
        getKentekensTranspas();
        const handleEscKey = (event) => {
            if (event.key === "Escape") {
              setShowDocument(false);
              setShowForm(false);
            }
          };
      
          document.addEventListener("keydown", handleEscKey);
      
          return () => {
            document.removeEventListener("keydown", handleEscKey);
          };
    }, []);
    
    return (
    <div id="main" className="dashboard">
        {
            medewerker ?
            <SideNav page="/schade" />
            :
            null
        }
        
        <div className="dashboard-content">
            {schade ? <DashboardHeader title={'Schade ' + schade.kenmerk + ' | ' + schade.kenteken} /> : null }
            <button className="back-to-list" onClick={() => schade.status.key !== 'gearchiveerd' ? navigate('/schades?dossier=' + schade.kenmerk) : navigate('/gearchiveerd?dossier=' + schade.kenmerk)}><Icon.CardList />Terug naar lijstweergave</button>
            {
                changeStatus ?
                <div className="statusinfo">
                <Select 
                    options={statussen} 
                    className={status? 'select-option status valid' : 'select-option'} 
                    placeholder="Status" value={status} 
                    onChange={(e) => setStatus(e)} 
                    disabled={isLoading} 
                />
                <button id="update-status" onClick={() => updateStatus()}>Status bijwerken</button>
                </div>
                :
                <>
                {
                schade ? 
                <div className="statusinfo">
                <span className="status" style={{color: schade.status.kleur}}>{schade.status.naam}</span>
                <Icon.PencilSquare style={{color:'#FFA500'}} size={20} id="edit-gegevens" className="edit-button" onClick={() => setChangeStatus(true)} />
                </div>
                :
                null
                }
                </>
            }
            
            <div className="schade-content">
                <div className="column">
                    <div className="block">
                        <div className="block-header">
                            <h3>Gegevens</h3>
                            <div className="icons">
                                {
                                    schade && schade.status.key === 'in_afwachting_reparateur' ?
                                    <>
                                        <Icon.Clipboard size={20} id="copy-werkbon-link" className="edit-button" onClick={() => { notifySuccess('Link naar werkbon gekopieërd'); navigator.clipboard.writeText('https://personeel.vsdv.nl/werkbon-reparateur/' + schade.uuid);}} />
                                        <Tooltip
                                            anchorId={'copy-werkbon-link'}
                                            place="top"
                                            content="Link naar werkbon kopiëren"
                                        />
                                    </>
                                    :
                                    null
                                }
                                <Icon.PencilSquare style={{color:'#FFA500'}} size={20} id="edit-gegevens" className="edit-button" onClick={() => {setShowForm(true); setEditGegevens(true); setEditSchadeInfo(false); setEditGetuigen(false);}} />
                                <Tooltip
                                    anchorId={'edit-gegevens'}
                                    place="top"
                                    content="Gegevens wijzigen"
                                />
                                <Icon.InfoCircle />
                            </div>
                        </div>
                        { schade && (schade.status.key === 'afgewerkt_intern' || schade.status.key === 'afgewerkt_tvm' ) ? <button id="archive" onClick={archiveSchade}><Icon.Archive /><span>Archiveren</span></button> : null }
                        <SchadeGegevens schade={schade} getSchade={getSchade} navigate={navigate} />
                    </div>
                    <SchadeBedragen schade={schade} />
                    <div className="block">
                        <div className="block-header">
                            <h3>Schadeinformatie</h3>
                            <div className="icons">
                            <Icon.PencilSquare style={{color:'#FFA500'}} size={20} id="edit-schadeinformatie" className="edit-button" onClick={() => {setShowForm(true); setEditGegevens(false); setEditSchadeInfo(true); setEditGetuigen(false);}} />
                                <Tooltip
                                    anchorId={'edit-schadeinformatie'}
                                    place="top"
                                    content="Schadeinformatie wijzigen"
                                />
                                <Icon.InfoCircle />
                            </div>
                        </div>
                        <SchadeInformatie schade={schade} />
                    </div>

                    {
                        schade ?
                        <div className="block">
                            <div className="block-header">
                                <h3>Getuigen</h3>
                                <div className="icons">
                                <Icon.PencilSquare style={{color:'#FFA500'}} size={20} id="edit-getuigen" className="edit-button" onClick={() => {setShowForm(true); setEditGegevens(false); setEditSchadeInfo(false); setEditGetuigen(true);}} />
                                    <Tooltip
                                        anchorId={'edit-getuigen'}
                                        place="top"
                                        content="Getuigen wijzigen"
                                    />
                                    <Icon.InfoCircle />
                                </div>
                            </div>
                            {
                                schade.getuigen.length > 0 && (schade.getuigen[0].naam || schade.getuigen[0].adres || schade.getuigen[0].telefoonnummer) ?
                                <table id="audit">
                                    <thead>
                                        <tr>
                                            <th>Naam</th>
                                            <th>Adres</th>
                                            <th>Telefoonnummer</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            schade.getuigen.map((item, index) =>  {
                                                return <tr key={index}>
                                                    <td>{item.naam}</td>
                                                    <td>{item.adres}</td>
                                                    <td>{item.telefoonnummer}</td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                                :
                                <p>Er zijn nog geen getuigen aan dit dossier toegevoegd</p>
                            }
                            
                            
                        </div>
                        :
                        null
                    }
                    

                    {
                        schade && !isLoading ?
                        <MyMapComponent
                            defaultZoom={9} 
                            isMarkerShown 
                            markers={[
                                {lat: Number(schade.plaats.lat), lng: Number(schade.plaats.lng)}
                            ]} 
                            lat={Number(schade.plaats.lat)}
                            lng={Number(schade.plaats.lng)}
                        />
                        :
                        null
                    }
                    
                </div>
                <div className="column">
                    <div className="block">
                        <div className="block-header">
                            <h3>Documenten</h3>
                            <div className="icons">
                                <Icon.FileEarmarkRichtext />
                            </div>
                        </div>
                        <SchadeDocumenten schade={schade} setShowDocument={setShowDocument} setShowSlider={setShowSlider} fetchPdf={fetchPdf} handleDownload={handleDownload} getFileContents={getFileContents} setDocumentTitle={setDocumentTitle} setImageUrl={setImageUrl} deleteDocument={deleteDocument} />
                        <div className="add-document">
                            <span>Document uploaden</span>
                            <form id="upload-document" onSubmit={uploadDocumentFs}>
                                <input type="text" placeholder="Documentnaam" value={schadeDocument.naam} onChange={(e) => handleChange('document', 'naam', e.target.value)} className="document-name" required />
                                <input type="file" ref={documentInputRef} name="document" placeholder="Bestand" accept={'image/jpeg,image/png,application/pdf,image/x-eps,message/rfc822'} onChange={(e) => handleChange('document', 'value', e.target.files[0])} className="form-field file" required />
                                <input type="submit" className="button" value="Uploaden"  />
                            </form>
                        </div>
                    </div>
                    <div className="block">
                        <div className="block-header">
                            <h3>Foto's</h3>
                            <div className="icons">
                                <Icon.Camera />
                            </div>
                        </div>
                        <SchadeFotos schade={schade} setShowDocument={setShowDocument} setIframePdf={setIframePdf} setShowSlider={setShowSlider} setDocumentTitle={setDocumentTitle} goToSlide={goToSlide} setImageUrl={setImageUrl} deleteImage={deleteImage} />
                        <div className="add-images">
                            <span>Foto's uploaden</span>
                            <form id="upload-images" onSubmit={uploadImages}>
                                <select defaultValue={imageType} 
                                    onChange={e => {
                                        setImageType(e.target.value);
                                    }} 
                                >
                                <option value="0">Eigen schade</option>
                                <option value="1">Schade tegenpartij</option>
                                <option value="2">Omgeving</option>
                                </select>
                                <input type="file" ref={ImageInputRef} name="document" placeholder="Bestand" accept={'image/jpeg,image/png'} onChange={(e) => setImages(e.target.files)} className="form-field files" required multiple />
                                <input type="submit" className="button" value="Uploaden"  />
                            </form>
                        </div>
                    </div>
                    <div className="block">
                        <div className="block-header">
                            <h3>Tijdlijn</h3>
                            <div className="icons">
                                <Icon.ListTask />
                            </div>
                        </div>
                        <SchadeTijdlijn schade={schade} />
                        <form id="add-log" onSubmit={submitLog}>
                            <textarea placeholder="Schrijf hier je log" value={log} onChange={(e) => setLog(e.target.value)} required></textarea>
                            <input type="submit" value="Toevoegen" className="button" />
                        </form>
                    </div>
                    <div className="block">
                        <div className="block-header">
                            <h3>Vrije opmerkingen</h3>
                            <div className="icons">
                                <Icon.ChatRightDots />
                            </div>
                        </div>
                        <SchadeCommunicatie schade={schade} />
                        <form id="add-log" onSubmit={submitBericht}>
                            <textarea placeholder="Schrijf bericht" value={bericht} onChange={(e) => setBericht(e.target.value)} required></textarea>
                            <label className="checkbox">
                                <input type="checkbox" checked={sendMailBericht} onChange={() => { sendMailBericht ? setSendMailBericht(false) : setSendMailBericht(true) }} />
                                <span className="checkbox-label">Verstuur mail van opmerking</span>
                            </label>
                            <input type="submit" value="Verstuur" className="button" />
                        </form>
                    </div>
                </div>
            </div>

            <div className={'edit-schade side-preview ' + (showForm ? 'show' : '')}>
                <div className="side-preview-header">
                    <h3>Schade bewerken</h3>
                    <Icon.XCircleFill onClick={() => setShowForm(false)} />
                </div>
                <div className="side-preview-content">
                    {
                        editGegevens ?
                        <div id="gegevens-form">
                            <label>Datum*</label>
                            <input type="date" name="datum" placeholder="Datum (dd-mm-jjjj)" className={gegevens.datum.value ? 'form-field valid' : 'form-field'} value={gegevens.datum.value} onChange={(e) => handleChange('gegevens', 'datum', e.target.value)} disabled={isLoading} />
                            <div className="two-column">
                                <div className="schade-field">
                                    <label>Kenmerk TVM</label>
                                    <input type="text" name="kenmerk_tvm" id="kenmerk_tvm" placeholder="Kenmerk TVM" value={gegevens.kenmerk_tvm.value} className={gegevens.kenmerk_tvm.value ? 'form-field valid' : 'form-field'} onChange={(e) => handleChange('gegevens', 'kenmerk_tvm', e.target.value)} />
                                </div>
                                <div className="schade-field">
                                    <label>Nota</label>
                                    <input type="text" name="nota" id="nota" placeholder="Nota" value={gegevens.nota.value} className={gegevens.nota.value ? 'form-field valid' : 'form-field'} onChange={(e) => handleChange('gegevens', 'nota', e.target.value)} />
                                </div>
                            </div>
                            <div className="two-column">
                                {
                                    schade && schade.charter ?
                                    <div className="select-dropdown">
                                        <label>Charter*</label>
                                        <Select options={charters} className={gegevens.charter.value? 'select-option valid' : 'select-option'} placeholder="Charter" value={gegevens.charter.value} onChange={(e) => handleChange('gegevens', 'charter', e)} disabled={isLoading} />
                                    </div>
                                    :
                                    <div className="select-dropdown">
                                        <label>Chauffeur*</label>
                                        <Select options={chauffeurs} className={gegevens.chauffeur_medewerker.value? 'select-option valid' : 'select-option'} placeholder="Chauffeur" value={gegevens.chauffeur_medewerker.value} onChange={(e) => handleChange('gegevens', 'chauffeur_medewerker', e)} disabled={isLoading} />
                                    </div>
                                }
                                <div className="select-dropdown">
                                    <label>Planner*</label>
                                    <Select options={planners} className={gegevens.planner_melder.value? 'select-option valid' : 'select-option'} placeholder="Planner" value={gegevens.planner_melder.value} onChange={(e) => handleChange('gegevens', 'planner_melder', e)} disabled={isLoading} />
                                </div>
                            </div>
                            <div className="two-column">
                                <div className="select-dropdown">
                                    <label>Kenteken*</label>
                                    <Select 
                                        options={kentekensTranspas}
                                        className={gegevens.kenteken.value? 'select-option valid' : 'select-option'}
                                        placeholder="Kenteken"
                                        value={gegevens.kenteken.value}
                                        onChange={(e) => handleChange('gegevens', 'kenteken', e)}
                                        isClearable={true} disabled={isLoading}
                                        getOptionLabel={(option)=>option.label}
                                        getOptionValue={(option)=>option.searchValue}
                                    />
                                </div>
                                <div className="select-dropdown">
                                    <label>Kenteken Trailer/Aanhanger</label>
                                    <Select 
                                        options={kentekensTranspas}
                                        className={gegevens.trailer.value ? 'select-option valid' : 'select-option'}
                                        placeholder="Kenteken Trailer/Aanhanger"
                                        value={gegevens.trailer.value}
                                        onChange={(e) => handleChange('gegevens', 'trailer', e)}
                                        isClearable={true} disabled={isLoading}
                                        getOptionLabel={(option)=>option.label}
                                        getOptionValue={(option)=>option.searchValue}
                                    />
                                </div>
                            </div>
                            <div className="two-column">
                                <div className="select-dropdown">
                                    <label>Type schade*</label>
                                    <Select options={schadeTypes} className={gegevens.type_schade.value? 'select-option valid' : 'select-option'} placeholder="Type schade" value={gegevens.type_schade.value} onChange={(e) => handleChange('gegevens', 'type_schade', e)} disabled={isLoading} />
                                </div>
                                <label className="checkbox">
                                    <input type="checkbox" checked={gegevens.schuldschade.value} onChange={changeSchuldChauffeur} />
                                    <span className="checkbox-label">Schuld van VSDV Chauffeur</span>
                                </label>
                            </div>
                            <div className="two-column">
                                <div className="switch">
                                    <label className={'item ' + (gegevens.verhaal_eigen.value === 'verhaal' ? 'current' : '')} onClick={() => handleChange('gegevens', 'verhaal_eigen', 'verhaal')}>Verhaalschade</label>
                                    <label className={'item ' + (gegevens.verhaal_eigen.value === 'eigen' ? 'current' : '')} onClick={() => handleChange('gegevens', 'verhaal_eigen', 'eigen')}>Eigen schade</label>
                                </div>
                            </div>
                            <div className="schade-field">
                                <label>{schade.soort_schade === 'ladingschade' ? 'Opdrachtgever' : 'Tegenpartij'}</label>
                                <input type="text" name="tegenpartij" id="tegenpartij" placeholder={schade.soort_schade === 'ladingschade' ? 'Opdrachtgever' : 'Tegenpartij'} value={gegevens.tegenpartij.value} className={gegevens.tegenpartij.value ? 'form-field valid' : 'form-field'} onChange={(e) => handleChange('gegevens', 'tegenpartij', e.target.value)} />
                            </div>
                            <div className="three-column">
                                <div className="schade-field">
                                    <label>Schadebedrag</label>
                                    <input type="number" name="schadebedrag" id="schadebedrag" placeholder="Schadebedrag" value={gegevens.schadebedrag.value} className={gegevens.schadebedrag.value ? 'form-field valid' : 'form-field'} onChange={(e) => handleChange('gegevens', 'schadebedrag', e.target.value)} />
                                </div>
                                <div className="schade-field">
                                    <label>Schadebedrag tegenpartij</label>
                                    <input type="number" name="schadebedrag_tegenpartij" id="schadebedrag_tegenpartij" placeholder="Schadebedrag tegenpartij" value={gegevens.schadebedrag_tegenpartij.value} className={gegevens.schadebedrag_tegenpartij.value ? 'form-field valid' : 'form-field'} onChange={(e) => handleChange('gegevens', 'schadebedrag_tegenpartij', e.target.value)} />
                                </div>
                                <div className="schade-field">
                                    <label>Eigen risico</label>
                                    <input type="number" name="eigen-risico" id="eigen-risico" placeholder="Eigen risico" value={gegevens.eigen_risico.value} className={gegevens.eigen_risico.value ? 'form-field valid' : 'form-field'} onChange={(e) => handleChange('gegevens', 'eigen_risico', e.target.value)} />
                                </div>
                            </div>
                            <div className="two-column">
                                <div className="schade-field">
                                    <label>Betaald door TVM</label>
                                    <input type="number" name="door-tvm" id="door-tvm" placeholder="Betaald door tvm" value={gegevens.betaald_door_tvm.value} className={gegevens.betaald_door_tvm.value ? 'form-field valid' : 'form-field'} onChange={(e) => handleChange('gegevens', 'betaald_door_tvm', e.target.value)} />
                                </div>
                                <div className="schade-field">
                                    <label>WA ER</label>
                                    <input type="number" name="wa-er" id="wa-er" placeholder="WA ER" value={gegevens.wa_er.value} className={gegevens.wa_er.value ? 'form-field valid' : 'form-field'} onChange={(e) => handleChange('gegevens', 'wa_er', e.target.value)} />
                                </div>
                            </div>
                            {
                                gegevensValid ?
                                <button id="save-schade" onClick={() => updateSchade('gegevens')}>Opslaan</button>
                                :
                                <p className="error">*Vul eerst alle verplichte velden in voordat je het dossier kunt bijwerken</p>
                            }
                        </div>
                        :
                        null
                    }
                    {
                        editSchadeInfo ?
                        <div id="schadeinfo-form">
                            <div className="two-column">
                                <div className="schade-field">
                                    <label>Land*</label>
                                    <Autocomplete
                                        id="land"
                                        apiKey={'AIzaSyDEb-vBIzMesXp3RrmhcffdY1oZUwvBLJE'}
                                        options={{
                                            types: ["country"],
                                        }}
                                        language="nl"
                                        onPlaceSelected={(place) => {
                                            handleChange('schade', 'land', place.address_components[0].short_name);
                                        }}
                                        className={schadeInfo.land.value ? 'form-field valid' : 'form-field'}
                                        placeholder="Land"
                                        onChange={(e) => {
                                            handleChange('schade', 'land', e.target.value);
                                            handleChange('schade', 'plaats', '');
                                            document.getElementById('plaats').value = '';
                                        }}
                                        value={schadeInfo.land.value}
                                    />
                                </div>
                                <div className="schade-field">
                                    <label>Plaats*</label>
                                    <Autocomplete
                                        id="plaats"
                                        apiKey={'AIzaSyDEb-vBIzMesXp3RrmhcffdY1oZUwvBLJE'}
                                        options={{
                                            //types: ["(regions)"],
                                            types: ["geocode"],
                                            componentRestrictions: { country: schadeInfo.land.value },
                                        }}
                                        language="nl"
                                        onPlaceSelected={(place) => {
                                            const latlng = {
                                                //naam: place.address_components[0].long_name,
                                                naam: extractPlace(place.address_components),
                                                lat: place.geometry.location.lat().toString(),
                                                lng: place.geometry.location.lng().toString()
                                            }
                                            handleChange('schade', 'plaats', latlng)
                                        }}
                                        className={schadeInfo.plaats.value ? 'form-field valid' : 'form-field'}
                                        placeholder={schadeInfo.land.value ? 'Plaats' : 'Plaats (selecteer eerst een land)'}
                                        onChange={() => handleChange('schade', 'plaats', '')}
                                        value={schadeInfo.plaats.value.naam}
                                        disabled={schadeInfo.land.value ? false : true}
                                    />
                                </div>
                            </div>
                            <div className="two-column">
                                <div className="schade-field">
                                    <label>Omschrijving*</label>
                                    <textarea placeholder="Schadeomschrijving" className={schadeInfo.schadeomschrijving.value ? 'form-field valid' : 'form-field'} value={schadeInfo.schadeomschrijving.value} rows="5" onChange={(e) => handleChange('schade', 'schadeomschrijving', e.target.value)}></textarea>
                                </div>
                                <div className="schade-field">
                                    <label>Oorzaak*</label>
                                    <textarea placeholder="Schadeoorzaak" className={schadeInfo.schadeoorzaak.value ? 'form-field valid' : 'form-field'} value={schadeInfo.schadeoorzaak.value} rows="5" onChange={(e) => handleChange('schade', 'schadeoorzaak', e.target.value)}></textarea>
                                </div>
                            </div>
                            <div className="schade-field">
                                <label>Actie richting chauffeur</label>
                                <textarea placeholder="Actie richting chauffeur" className={schadeInfo.actie_richting_chauffeur.value ? 'form-field valid' : 'form-field'} value={schadeInfo.actie_richting_chauffeur.value} rows="5" onChange={(e) => handleChange('schade', 'actie_richting_chauffeur', e.target.value)}></textarea>
                            </div>
                            <label>Soort vervoer*</label>
                            <Select options={soortenVervoer} className={schadeInfo.soort_vervoer.value? 'select-option valid' : 'select-option'} placeholder="Soort vervoer" value={schadeInfo.soort_vervoer.value} onChange={(e) => handleChange('schade', 'soort_vervoer', e)} disabled={isLoading} />
                        {
                            schadeInfoValid ?
                            <button id="save-schade" onClick={() => updateSchade('schadeinfo')}>Opslaan</button>
                            :
                            <p className="error">*Vul eerst alle verplichte velden in voordat je het dossier kunt bijwerken</p>
                        }
                        </div>
                        :
                        null
                    }
                    {
                        editGetuigen ?
                        <div id="getuigen-form">
                        <div className="getuigen">
                            {
                                getuigen.value.map((item, index) =>  {
                                    return <div key={index} className="fields">
                                        <input type="text" placeholder="Naam" className={item.naam ? 'form-field valid' : 'form-field'} value={item.naam} onChange={(e) => handleGetuigenChange('naam', e.target.value, index)} disabled={isLoading} />
                                        <input type="text" placeholder="Adres" className={item.adres ? 'form-field valid' : 'form-field'} value={item.adres} onChange={(e) => handleGetuigenChange('adres', e.target.value, index)} disabled={isLoading} />
                                        <input type="text" placeholder="Telefoonnummer" className={item.telefoonnummer ? 'form-field valid' : 'form-field'} value={item.telefoonnummer} onChange={(e) => handleGetuigenChange('telefoonnummer', e.target.value, index)} disabled={isLoading} />
                                    </div>
                                })
                            }
                            <div className="actions">
                                {
                                    getuigen.value.length > 1 ?
                                    <button className="remove" onClick={() => handleGetuigen('remove')}><Icon.DashCircleFill /></button>
                                    :
                                    null
                                }  
                                <button className="add" onClick={() => handleGetuigen('add')}><Icon.PlusCircleFill /></button>
                            </div>
                        </div>
                        {
                            getuigenValid ?
                            <button id="save-schade" onClick={() => updateSchade('getuigen')}>Opslaan</button>
                            :
                            <p className="error">*Vul eerst alle verplichte velden in voordat je het dossier kunt bijwerken</p>
                        }
                        </div>
                        :
                        null
                    }
                </div>
            </div>
            <div className={'view-document side-preview ' + (showDocument ? 'show' : '')}>
                <div className="side-preview-header">
                    <h3>{documentTitle}</h3>
                    <Icon.XCircleFill onClick={() => setShowDocument(false)} />
                </div>
                <div className="side-preview-content">
                    {
                        iframePdf && !showSlider ?
                        <iframe src={`${iframePdf}#view=fitH`} title="view PDF" height="100%" width="100%" />
                        :
                        null
                    }
                    {
                        imageUrl && !showSlider ?
                        <img src={imageUrl} />
                        :
                        null
                    }
                    {
                        showSlider ?
                        <Slider {...sliderSettings} className="schade-fotos" ref={sliderRef}>
                            {
                                schadeFotosSlides.map((item, index) =>  {
                                    return <div key={index}>
                                        <img src={item.image} />
                                        <label>{item.label}</label>
                                    </div>
                                })
                            }
                        </Slider>
                        :
                        null
                    }
                    
                </div>
            </div>
            <div className={'blurred ' + (showForm || showDocument ? 'show' : '')} onClick={() => {setShowForm(false); setShowDocument(false);}}></div>
            <div className={'blurred loader-offerte ' + (isLoading ? 'show' : '')}>
                <ThreeDots 
                    height="80" 
                    width="80" 
                    radius="9"
                    color="#001c43" 
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                />
            </div>
            <Toaster />
        </div>
        {
            medewerker ?
            <BottomNav page="/schade" />
            :
            null
        }
    <img src={svzArtwork} className="artwork" />    
    </div>
    );
};
export default Schade;