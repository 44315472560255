import { useState, useRef } from 'react';

//3rd party
import * as Icon from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import { saveAs } from "file-saver";

//styles
import './schadeFotos.css';
import 'react-loading-skeleton/dist/skeleton.css';

const Example = (props) => <button onClick={() => { props.setIframePdf(''); props.setShowSlider(true); props.goToSlide(props.id); props.setShowDocument(true); props.setDocumentTitle(props.title); props.setImageUrl(props.url); }}><Icon.ZoomIn /><span>Voorbeeld</span></button>;
const Download = (props) => <button onClick={() => { saveAs(props.url, props.name) }}><Icon.Download /><span>Download</span></button>;
const Delete = (props) => <button onClick={() => { props.deleteImage(props.objKey, props.index) }}><Icon.Trash3 /></button>;

const SchadeFotos = (props) => {

    const environment = process.env.REACT_APP_APP_URL;
    const [isLoading, setIsloading] = useState(false);

    return (
        <>
        {
            props.schade ?
            <>
            {
                props.schade.fotos_eigen_schade || props.schade.fotos_schade_tegenpartij || props.schade.fotos_omgeving ?
                <div className="document-list">
                    {
                        props.schade.fotos_eigen_schade ?
                        props.schade.fotos_eigen_schade.map((item, index) =>  {
                            const imageNumber = index + 1;
                            return <div key={index} className="item">
                                <span className="document-name">Foto {index + 1} <span className="type">eigen schade</span></span>
                                <div className="actions">
                                    <Example setIframePdf={props.setIframePdf} setShowSlider={props.setShowSlider} goToSlide={props.goToSlide} id={item.id} url={environment + item.url} setShowDocument={props.setShowDocument} title={'Eigen schade'} setDocumentTitle={props.setDocumentTitle} setImageUrl={props.setImageUrl} />
                                    <Download name={'eigen-schade-' + imageNumber + '-' + props.schade.kenmerk} url={environment + item.url} />
                                    {
                                        props.deleteImage ?
                                        <Delete deleteImage={props.deleteImage} objKey={'fotos_eigen_schade'} index={index} />
                                        :
                                        null
                                    }
                                </div>
                                </div>
                        })
                        :
                        null
                    }
                    {
                        props.schade.fotos_schade_tegenpartij ?
                        props.schade.fotos_schade_tegenpartij.map((item, index) =>  {
                            const imageNumber = index + 1;
                            return <div key={index} className="item">
                                <span className="document-name">Foto {index + 1} <span className="type">schade tegenpartij</span></span>
                                <div className="actions">
                                    <Example setIframePdf={props.setIframePdf} setShowSlider={props.setShowSlider} goToSlide={props.goToSlide} id={item.id} url={environment + item.url} setShowDocument={props.setShowDocument} title={'Schade tegenpartij'} setDocumentTitle={props.setDocumentTitle} setImageUrl={props.setImageUrl} />
                                    <Download name={'schade-tegenpartij-' + imageNumber + '-' + props.schade.kenmerk} url={environment + item.url} />
                                    {
                                        props.deleteImage ?
                                        <Delete deleteImage={props.deleteImage} objKey={'fotos_schade_tegenpartij'} index={index} />
                                        :
                                        null
                                    }
                                </div>
                                </div>
                        })
                        :
                        null
                    }
                    {
                        props.schade.fotos_omgeving ?
                        props.schade.fotos_omgeving.map((item, index) =>  {
                            const imageNumber = index + 1;
                            return <div key={index} className="item">
                                <span className="document-name">Foto {index + 1} <span className="type">omgeving</span></span>
                                <div className="actions">
                                    <Example setIframePdf={props.setIframePdf} setShowSlider={props.setShowSlider} goToSlide={props.goToSlide} id={item.id} url={environment + item.url} setShowDocument={props.setShowDocument} title={'Omgeving'} setDocumentTitle={props.setDocumentTitle} setImageUrl={props.setImageUrl} />
                                    <Download name={'omgeving-' + imageNumber + '-' + props.schade.kenmerk} url={environment + item.url} />
                                    {
                                        props.deleteImage ?
                                        <Delete deleteImage={props.deleteImage} objKey={'fotos_omgeving'} index={index} />
                                        :
                                        null
                                    }
                                </div>
                                </div>
                        })
                        :
                        null
                    }
                    
                </div>
                :
                <p>Geen foto's beschikbaar</p>
            }
            </>
            :
            <>
            <Skeleton height={20} style={{marginBottom:10}} />
            <Skeleton height={20} style={{marginBottom:10}} />
            <Skeleton height={20} style={{marginBottom:10}} />
            <Skeleton height={20} style={{marginBottom:10}} />
            </>
        }
        
        </>
    );
};
export default SchadeFotos;