import { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";

//Helper functions
import { getSettings, notifyError, notifySuccess, } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import { Chart as ChartJS, RadialLinearScale, ArcElement, Tooltip, Legend } from 'chart.js';
import { PolarArea } from 'react-chartjs-2';
import toast, { Toaster } from 'react-hot-toast';

//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';
import MyMapComponent from '../../../components/maps/googleMaps';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';
import svzArtwork from '../../../assets/images/artwork-svz.svg';

//styles
import './kenteken.css';
import 'react-loading-skeleton/dist/skeleton.css';

const Kenteken = () => {

    ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

    //Set variables
    const { kenteken } = useParams();
    const environment = process.env.REACT_APP_APP_URL;
    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const medewerker = JSON.parse(localStorage.getItem('medewerker'));
    const [schades, setSchades] = useState([]);
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
            label: '',
            data: [],
            backgroundColor: [],
            borderWidth: 1,
            },
        ],
    });
    const [markers, setMarkers] = useState([]);

    const getKentekenInfo = async () => {
        try {
            const response = await api.get('/schades/schades-kenteken/' + kenteken);
            if (response.data) {
                setSchades(response.data);
                var chartDataObj = {
                    labels: [],
                    datasets: [
                        {
                            label: 'Aantal schades',
                            data: [],
                            backgroundColor: []
                        }
                    ],
                    borderWidth: 1
                };
                const markersArray = [];
                response.data.map((item, index) =>  {
                    if (chartDataObj.labels.includes(item.schadetype.naam)) {
                        const arrayIndex = chartDataObj.labels.indexOf(item.schadetype.naam);
                        chartDataObj.datasets[0].data[arrayIndex]++;
                    } else {
                        chartDataObj.labels.push(item.schadetype.naam);
                        chartDataObj.datasets[0].data.push(1);
                        chartDataObj.datasets[0].backgroundColor.push(item.schadetype.kleur_grafiek);
                    }
                    const markerObj = { lat: Number(item.plaats.lat), lng: Number(item.plaats.lng) };
                    markersArray.push(markerObj);
                })
                setChartData(chartDataObj);
                setMarkers(markersArray)
            }
        } catch (error) {
            console.log(error);
        }
    }

    //Check if user is authenticated, else redirect to login
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        getKentekenInfo();
    }, []);
    
    return (
    <div id="main" className="dashboard">
        {
            medewerker ?
            <SideNav page="/kenteken" />
            :
            null
        }
        
        <div className="dashboard-content">
            <DashboardHeader title={kenteken} />
            <div className="schade-content">
                <div className="column">
                    <div className="block">
                        <div className="block-header">
                            <h3>Overzicht schades</h3>
                        </div>
                        <div className="number-blocks">
                            {/* <div className="number-block">
                                <p className="number-block-title">Totaal schades</p>
                                <p className="number-block-value">{'Hier totaalbedrag'}</p>
                            </div> */}
                            <div className="number-block">
                                <p className="number-block-title">Aantal schades</p>
                                <p className="number-block-value">{schades.length}</p>
                            </div>
                        </div>
                    </div>
                    <div className="block">
                        <div className="block-header">
                            <h3>Lijst schades</h3>
                        </div>
                        <div className="list-schades">
                            {
                                schades.map((item, index) =>  {
                                    return <div className="item">
                                        <p>{item.kenmerk}</p>
                                        <span style={{color:item.status.kleur}}>{item.status.naam}</span>
                                        <button onClick={() => navigate('/schades/' + item.id)} className="red-button button">Bekijken</button>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    <div className="block">
                        <div className="block-header">
                            <h3>Op de kaart</h3>
                        </div>
                        {
                            markers.length > 0 ?
                            <MyMapComponent
                                defaultZoom={markers.length > 1 ? 7 : 8} 
                                isMarkerShown 
                                lat={markers.length > 1 ? 52.092876 : markers[0].lat} 
                                lng={markers.length > 1 ? 5.104480 : markers[0].lng}
                                markers={markers}
                            />
                            :
                            null
                        }
                    </div>
                </div>
                <div className="column">
                    <div className="chart">
                        <PolarArea data={chartData} />
                    </div>
                </div>
            </div>
            <Toaster />
        </div>
        {
            medewerker ?
            <BottomNav page="/kenteken" />
            :
            null
        }
    <img src={svzArtwork} className="artwork" />    
    </div>
    );
};
export default Kenteken;