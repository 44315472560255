import { useState, useEffect } from 'react';
import { useSearchParams, Link, useNavigate } from "react-router-dom";

//Helper functions
import { getSettings } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//Styles
import '../../../index.css';
import '../css/public.css';

//Assets
import vsdvLogo from '../../../assets/images/logo.svg';
import vsdvArtwork from '../../../assets/images/logo-detail.svg';
import svzArtwork from '../../../assets/images/artwork-svz.svg';

//3rd party
import { TailSpin } from  'react-loader-spinner';

const ResetPassword = () => {

    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');

    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn === 'true') {
            navigate('/');
        }
    }, []);

    //Make params accessible
    const [params, setParams] = useSearchParams();
    //Get code param
    const code = params.get("code");

    //Variables
    const [password, setPassword] = useState('');
    const [repeatedPassword, setRepeatedPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [info, setInfo] = useState('Vul hieronder je e-mailadres in en we sturen je een link om je wachtwoord opnieuw in te stellen');
    const [passwordChanged, setPasswordChanged] = useState(false);

    //Function to handle form submission for resetting password
    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage('');
        setIsLoading(true);
        if (!password) {
            setErrorMessage('Vul een nieuw wachtwoord in');
            setIsLoading(false);
            return;
        }
        if (!repeatedPassword) {
            setErrorMessage('Herhaal het ingevulde wachtwoord');
            setIsLoading(false);
            return;
        }
        if (password.length < 6) {
            setErrorMessage('Voer een wachtwoord in van minimaal 6 karakters');
            setIsLoading(false);
            return;
        }
        if (password !== repeatedPassword) {
            setErrorMessage('De ingevoerde wachtwoorden komen niet overeen');
            setIsLoading(false);
            return;
        }
        try {
            const response = await api.post('/auth/reset-password', {
                "code": code,
                "password": password,
                "passwordConfirmation": repeatedPassword
            });
            if (response.data) {
                setInfo('Je wachtwoord is correct opnieuw ingesteld. Klik op onderstaande link om in te loggen.');
                setPasswordChanged(true);
            }
        } catch (error) {
            switch(error.response.status) {
                case 429:
                    setErrorMessage('Er is iets fout gegaan (429). Probeer het later nog eens.');
                  break;
                //Default for 400 
                default:
                    setErrorMessage('De code is verlopen of incorrect. Kies opnieuw voor wachtwoord vergeten om een nieuwe link te ontvangen');
            }
        }
        setIsLoading(false);
    }

    return (
        <div id="login">
            <div className="column login">
                <div className="loginform">
                    <img src={vsdvLogo} alt="VSDV Personeel" />
                    <p>{info}</p>
                    {
                        !passwordChanged ?
                        <form id="reset-password" className="user-login" onSubmit={handleSubmit}>
                            <input type="password" name="password-personeel" placeholder="Nieuw wachtwoord" onChange={(e) => setPassword(e.target.value)} className="form-field" />
                            <input type="password" name="repeat-password-personeel" placeholder="Nieuw wachtwoord herhalen" onChange={(e) => setRepeatedPassword(e.target.value)} className="form-field" />
                            {
                                isLoading ?
                                <div className="loader">
                                    <TailSpin
                                        height="50"
                                        width="50"
                                        color="#CB0A32"
                                        ariaLabel="tail-spin-loading"
                                        radius="1"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    />
                                </div>
                                :
                                <input type="submit" value="Opslaan" className="submit-form" />
                            }
                        </form>
                        :
                        <Link to="/" className="login-button">Inloggen</Link>
                    }
                    
                    {
                        isLoading || passwordChanged ?
                        null
                        :
                        <div>
                            <p className="error">{errorMessage}</p>
                            <Link to="/login" className="forgot-password">Terug naar inloggen</Link>
                        </div>
                    }
                </div>
            </div>
            <div className="column image"></div>
            <img src={svzArtwork} className="artwork" />
        </div>
      );
  
};
export default ResetPassword;