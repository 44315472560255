import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

//Helper functions
import { getSettings, notifyError, notifySuccess, getTimeFromSeconds, countTotalSeconds } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import toast, { Toaster } from 'react-hot-toast';
import * as Icon from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import DataTable from 'react-data-table-component';
import { ThreeDots } from  'react-loader-spinner';
import Rodal from 'rodal';
import YouTube from 'react-youtube';
import Lottie from "lottie-react";

//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';
import Registratiedag from '../../../components/registreren/registratiedag';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';
import svzArtwork from '../../../assets/images/artwork-svz.svg';
import savedAnimation from "../../../assets/animations/checkmark.json";

//styles
import './registraties-controleren.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'rodal/lib/rodal.css';

const Status = (props) => {
    if (props.status === 'deels_ingestuurd') {
        var status = 'Uren ' + props.month + ' ingestuurd';
    } else if (props.status === 'deels_goedgekeurd') {
        var status = 'Deels goegekeurd (' + props.month + ')';
    } else if (props.status === 'deels_goedgekeurd_def') {
        var status = 'Deels definitief goedgekeurd'
    } else if (props.status === 'goedgekeurd_def') {
        var status = 'Definitief goedgekeurd'
    } else if (props.status === 'deels_verwerkt') {
        var status = 'Deels verwerkt';
    } else {
        var status = props.status;
    }
    return <p className={props.status + ' status'}>{status}</p>;
}
const Bekijken = (props) => <button className="view" onClick={() => {props.setCurrentId(props.id); props.setOverzicht(false); props.loadRegistratieById(props.registraties, props.id)}}>Bekijken</button>

const AlleRegistraties = () => {

    //Set variables
    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const medewerker = JSON.parse(localStorage.getItem('medewerker'));
    const videotutorials = JSON.parse(localStorage.getItem('videotutorials'));
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paramWeek = queryParams.get('week');
    const paramYear = queryParams.get('jaar');
    const paramDivisie = queryParams.get('divisie');
    const [showModalHelp, setShowModalHelp] = useState(false);
    const [showModalOpmerking, setShowModalOpmerking] = useState(false);
    const [opmerking, setOpmerking] = useState('');
    const [sendingOpmerking, setSendingOpmerking] = useState(false);
    const [showModalLogs, setShowModalLogs] = useState(false);
    const [overzicht, setOverzicht] = useState(true);
    const [currentId, setCurrentId] = useState(0);
    const [listRegistraties, setListRegistraties] = useState([]);
    const [registraties, setRegistraties] = useState([]);
    const [allRegistraties, setAllRegistraties] = useState([]);
    const [totalHours, setTotalHours] = useState('00:00');
    const [verlofHours, setVerlofHours] = useState('00:00');
    const [contractHours, setContractHours] = useState('00:00');
    const [vastSalaris, setVastSalaris] = useState(false);
    const [logs, setLogs] = useState([]);
    const [urenTypes, setUrenTypes] = useState([]);
    const arrayDays = ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'];
    const [year, setYear] = useState(paramYear ? paramYear : moment().format('YYYY'));
    const [currentMonth, setCurrentMonth] = useState(moment().format('MMMM'));
    const [weekNumber, setWeekNumber] = useState(paramWeek ? Number(paramWeek) : moment().isoWeek() - 1);
    const [divisies, setDivisies] = useState([]);
    const [divisie, setDivisie] = useState(paramDivisie ? Number(paramDivisie) : 0);
    const [standplaatsen, setStandplaatsen] = useState([]);
    const [standplaats, setStandplaats] = useState(0);
    const [types, setTypes] = useState([]);
    const [type, setType] = useState(0);
    const [nextRegistratie, setNextRegistratie] = useState(0);
    const [previousRegistratie, setPreviousRegistratie] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const youtubePlayer = useRef(null);
    const opts = {
        height: '280',
        width: '500',
        playerVars: {
          autoplay: 0,
        },
    };
    const [showSaving, setShowSaving] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      };

    //Function to do custom sort on status because it is wrapped in <p> tag
    const customStatusSort = (rowA, rowB) => {
        const a = rowA.status.props.status;
        const b = rowB.status.props.status;
        if (b > a) {
            return 1;
        }
        if (a > b) {
            return -1;
        }
        return 0;
    };

    //Columns for datatable
    const columns = [
        {
            name: 'Naam',
            selector: row => row.naam,
            sortable: true,
        },
        {
            name: 'Personeelsnummer',
            selector: row => row.personeelsnummer,
            sortable: false,
        },
        {
            name: 'Controleur 1',
            selector: row => row.controleur_een,
            sortable: true,
        },
        {
            name: 'Controleur 2',
            selector: row => row.controleur_twee,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            sortFunction: customStatusSort
        },
    ];
    //Translations for datatable
    const paginationComponentOptions = {
        rowsPerPageText: 'Rijen per pagina',
        rangeSeparatorText: 'van',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Selecteer alles',
    };

    //Function to get registraties
    const getRegistraties = async () => {
        if (!weekNumber) {
            notifyError('Vul een weeknummer in');
            return;
        }
        if (Number(weekNumber) < 1 || Number(weekNumber) > 52) {
            notifyError('Kies een weeknummer van 1 t/m 52');
            return;
        }
        if (!year) {
            notifyError('Vul een jaar in');
            return;
        }
        if (year.length !== 4) {
            notifyError('Vul een geldig jaartal in');
            return;
        }
        setIsLoading(true);
        try {
            const response = await api.post('/registraties/alle-registraties', {
                "data" : {
                    "week": weekNumber,
                    "jaar": year
                }
            });
            if (response.data) {
                var arrayData = [];
                response.data.map((item, index) =>  {
                    const obj = {
                        id: item.id,
                        naam: item.medewerker.voornaam + ' ' + item.medewerker.achternaam,
                        personeelsnummer: item.medewerker.personeelsnummer,
                        controleur_een: item.medewerker.controleur_uren.voornaam + ' ' + item.medewerker.controleur_uren.achternaam,
                        controleur_twee: item.medewerker.controleur_twee_uren.voornaam + ' ' + item.medewerker.controleur_twee_uren.achternaam,
                        status: <Status status={item.status} month={moment(item.maandag.datum).format('MMMM')} />,
                    };
                    arrayData.push(obj);
                })  
                setListRegistraties(arrayData);
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            getRegistraties();
        }
    };

    //Check if user is authenticated and else redirect to login, else get registraties
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        getRegistraties();
    }, []);
    
    return (
    <div id="main" className="dashboard">
        <SideNav page="/alle-registraties" />
        <div className="dashboard-content">
            <DashboardHeader title="Alle registraties" />
        
            <div className="controleren-header">
                <div className="dropdowns">
                    <p>Week</p>
                    <input type="number" placeholder="12" value={weekNumber} onChange={(e) => setWeekNumber(e.target.value)} onKeyDown={handleKeyDown} />
                    <p>-</p>
                    <input type="number" placeholder={year} value={year} onChange={(e) => setYear(e.target.value)} onKeyDown={handleKeyDown} />
                    <button disabled={isLoading} onClick={() => getRegistraties()}>Registraties ophalen</button>
                </div>
            </div>

            {
                isLoading ?
                <div className="loader-registraties">
                <ThreeDots 
                    height="80" 
                    width="80" 
                    radius="9"
                    color="#001c43" 
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                />
                </div>
                :
                <>
                {
                    listRegistraties.length > 0 ?
                    <div className="registraties">
                        <DataTable
                            columns={columns}
                            data={listRegistraties}
                            pagination
                            paginationComponentOptions={paginationComponentOptions}
                            paginationPerPage={200}
                            fixedHeader
                            defaultSortFieldId={5}
                        />
                    </div>
                    :
                    <h3>Er zijn geen registraties gevonden</h3>
                }
                </>
            }

            <Toaster position="bottom-center" />
        </div>
        <BottomNav page="/alle-registraties" />
        <img src={svzArtwork} className="artwork" />
    </div>
    );
};
export default AlleRegistraties;