import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";

//Helper functions
import { getSettings, notifyError, notifySuccess, } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import * as Icon from 'react-bootstrap-icons';
import toast, { Toaster } from 'react-hot-toast';
import Lottie from "lottie-react";
import { PDFViewer, BlobProvider, pdf, Document, View, Image, Page, Text, Font, PDFDownloadLink, StyleSheet, Svg, Polygon, G } from '@react-pdf/renderer';
import { saveAs } from "file-saver";

//Components
import DashboardHeader from '../../../components/dashboard/header';
import SchadeFotos from '../../../components/schade/schadeFotos';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';
import svzArtwork from '../../../assets/images/artwork-svz.svg';
import pdfLogo from '../../../assets/images/pdf-logo.png';
import svzPdfLogo from '../../../assets/images/logo-svz.png';
import pdfArtwork from '../../../assets/images/artwork.png';
import AvertaSemiBold from '../../../assets/fonts/AvertaStd-Semibold.ttf';
import AvertaRegular from '../../../assets/fonts/Averta-Regular.otf';
import creatingDossierAnimation from "../../../assets/animations/create-dossier.json";

//styles
import './werkbon.css';
import 'react-loading-skeleton/dist/skeleton.css';

const formatBedrag = (value) => {
    var formatted = Number(value).toFixed(2);
    formatted = formatted.toString().replace(".", ",");
    return formatted;
}

// Register font
Font.register({family: 'AvertaRegular', src: AvertaRegular });
Font.register({family: 'AvertaSemiBold', src: AvertaSemiBold });

// Create styles
const styles = StyleSheet.create({
    page: {
        padding: 35,
        fontSize: 12,
        color: '#001E4E'
    },
    header: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center'
    },
    logo: {
        width: 70,
        backgroudColor: 'yellow'
    },
    title: {
        width: 200,
    },
    schadeInfo: {
        width: 170
    },
    schadeInfoItem: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5
    },
    schadeInfoLabel: {
        fontFamily: 'AvertaRegular',
        fontSize: 11
    },
    schadeInfoValue: {
        fontFamily: 'AvertaSemiBold',
        fontSize: 11
    },
    extraInfo: {
        marginTop: 50
    },
    extraInfoLabel: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        gap: 10,
        marginBottom: 10
    },
    label: {
        width: '12%',
        fontFamily: 'AvertaRegular',
        fontSize: 11
    },
    extraInfoValue: {
        width: '85%',
        fontFamily: 'AvertaSemiBold',
        fontSize: 11
    },
    table: {
        marginTop: 50
    },
    tableHeaders: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        flexDirection: 'row',
        borderBottom: 1,
        borderBottomColor: '#D0D0D0',
        paddingBottom: 10
    },
    row: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        flexDirection: 'row',
        marginTop: 10
    },
    columnHeaderAantal: {
        width: '10%',
        fontSize: 10,
        fontFamily: 'AvertaRegular'
    },
    rowItemAantal: {
        width: '10%',
        fontFamily: 'AvertaRegular',
        fontSize: 11
    },
    columnHeaderOnderdeel: {
        width: '30%',
        fontSize: 10,
        fontFamily: 'AvertaRegular'
    },
    rowItemOnderdeel: {
        width: '30%',
        fontFamily: 'AvertaRegular',
        fontSize: 11
    },
    columnHeaderSoort: {
        width: '15%',
        fontSize: 10,
        fontFamily: 'AvertaRegular'
    },
    rowItemSoort: {
        width: '15%',
        fontFamily: 'AvertaRegular',
        fontSize: 11
    },
    columnHeaderMerk: {
        width: '15%',
        fontSize: 10,
        fontFamily: 'AvertaRegular'
    },
    rowItemMerk: {
        width: '15%',
        fontFamily: 'AvertaRegular',
        fontSize: 11
    },
    columnHeaderStukprijs: {
        width: '15%',
        fontSize: 10,
        fontFamily: 'AvertaRegular',
        textAlign: 'right'
    },
    rowItemStukprijs: {
        width: '15%',
        fontFamily: 'AvertaRegular',
        fontSize: 11,
        textAlign: 'right'
    },
    columnHeaderTotaal: {
        width: '15%',
        fontSize: 10,
        fontFamily: 'AvertaRegular',
        textAlign: 'right'
    },
    rowItemTotaal: {
        width: '15%',
        fontFamily: 'AvertaRegular',
        fontSize: 11,
        textAlign: 'right'
    },
    totals: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        gap: 20,
        borderTop: 1,
        borderTopColor: '#D0D0D0',
        marginTop: 10,
        paddingTop: 20
    },
    totalsText: {
        fontFamily: 'AvertaRegular',
        fontSize: 14
    },
    artwork: {
        position: 'absolute',
        bottom: -35,
        right: -100,
        width: 600,
        height: 200
    }
});

// Create Document Component
const MyDocument = (props) => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>

            <View style={styles.title}>
                <Text style={{ fontFamily: 'AvertaSemiBold', fontSize: 13, marginBottom: 5 }}>Taxatie {props.gegevens.reparateur.value}</Text>
                <Text style={{ fontFamily: 'AvertaSemiBold', fontSize: 13 }}>schade {props.kenmerk}</Text>
            </View>
            <View style={styles.schadeInfo}>
                <View style={styles.schadeInfoItem}><Text style={styles.schadeInfoLabel}>Datum:</Text><Text style={styles.schadeInfoValue}>{moment(props.gegevens.datum.value).format('DD-MM-YYYY')}</Text></View>
                <View style={styles.schadeInfoItem}><Text style={styles.schadeInfoLabel}>Bedrijfsnaam:</Text><Text style={styles.schadeInfoValue}>{props.bedrijf}</Text></View>
                <View style={styles.schadeInfoItem}><Text style={styles.schadeInfoLabel}>Kenteken:</Text><Text style={styles.schadeInfoValue}>{props.kenteken}</Text></View>
                <View style={styles.schadeInfoItem}><Text style={styles.schadeInfoLabel}>Soort voertuig:</Text><Text style={styles.schadeInfoValue}>{props.gegevens.soort_voertuig.value}</Text></View>
                <View style={styles.schadeInfoItem}><Text style={styles.schadeInfoLabel}>Monteur:</Text><Text style={styles.schadeInfoValue}>{props.gegevens.monteur.value}</Text></View>
            </View>
        </View>
        <View style={styles.extraInfo}>
            { props.gegevens.uurloon.value ? <View style={styles.extraInfoLabel}><Text style={styles.label}>Uurloon:</Text> <Text style={styles.extraInfoValue}>€ {props.gegevens.uurloon.value}</Text></View> : null }
            { props.gegevens.opmerking.value ? <View style={styles.extraInfoLabel}><Text style={styles.label}>Opmerking:</Text> <Text style={styles.extraInfoValue}>{props.gegevens.opmerking.value}</Text></View> : null }
        </View>
        <View style={styles.table}>
            <View style={styles.tableHeaders}>
                <Text style={styles.columnHeaderAantal}>Aantal</Text>
                <Text style={styles.columnHeaderOnderdeel}>Onderdeel</Text>
                <Text style={styles.columnHeaderSoort}>Soort</Text>
                <Text style={styles.columnHeaderMerk}>Merk</Text>
                <Text style={styles.columnHeaderStukprijs}>Stukprijs</Text>
                <Text style={styles.columnHeaderTotaal}>Subtotaal</Text>
            </View>
            {
                props.items.value.length > 0 && props.items.value[0].stukprijs > 0 ?
                props.items.value.map((item, index) =>  {
                    return <View key={index} style={styles.row}>
                        <Text style={styles.rowItemAantal}>{item.aantal}</Text>
                        <Text style={styles.rowItemOnderdeel}>{item.onderdeel}</Text>
                        <Text style={styles.rowItemSoort}>{item.soort}</Text>
                        <Text style={styles.rowItemMerk}>{item.merk}</Text>
                        <Text style={styles.rowItemStukprijs}>{'€ ' + formatBedrag(item.stukprijs)}</Text>
                        <Text style={styles.rowItemTotaal}>{'€ ' + formatBedrag(item.stukprijs * item.aantal)}</Text>
                    </View>
                })
                :
                null
            }
            {
                props.gegevens.uurloon.value && props.gegevens.totale_uren.value ?
                <View style={styles.row}>
                    <Text style={styles.rowItemAantal}>{props.gegevens.totale_uren.value}</Text>
                    <Text style={styles.rowItemOnderdeel}>Totale uren</Text>
                    <Text style={styles.rowItemSoort}></Text>
                    <Text style={styles.rowItemMerk}></Text>
                    <Text style={styles.rowItemStukprijs}>{'€ ' + formatBedrag(props.gegevens.uurloon.value)}</Text>
                    <Text style={styles.rowItemTotaal}>{'€ ' + formatBedrag(props.gegevens.uurloon.value * props.gegevens.totale_uren.value)}</Text>
                </View>
                :
                null
            }
        </View>
        <View style={styles.totals}>
            <Text style={styles.totalsText}>Totaal:</Text>
            <Text style={styles.totalsText}>{'€ ' + formatBedrag(props.totaalprijs)}</Text>
        </View>
      </Page>
    </Document>
  );

const WerkbonReparateur = () => {

    //Set variables
    const { uuid } = useParams();
    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const medewerker = JSON.parse(localStorage.getItem('medewerker'));
    const [isLoading, setIsLoading] = useState(true);
    const [nothingFound, setNothingFound] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [schade, setSchade] = useState('');
    const [uploadOwnDocument, setUploadOwnDocument] = useState(false);
    const [bestand, setBestand] = useState('');
    const [totalPrice, setTotalPrice] = useState(0);
    const [showDocument, setShowDocument] = useState(false);
    const [documentTitle, setDocumentTitle] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [iframePdf, setIframePdf] = useState('');
    const [creatingDossier, setCreatingDossier] = useState(false);
    const [downloadedFile, setDownloadedFile] = useState(null);
    const lottieAnimation = useRef();

    const [gegevensValid, setGegevensValid] = useState(false);
    const [gegevens, setGegevens] = useState({
        reparateur: {
            value: '',
            required: true
        },
        datum: {
            value: '',
            required: true
        },
        soort_voertuig: {
            value: '',
            required: true
        },
        monteur: {
            value: '',
            required: true,
        },
        uurloon: {
            value: '',
            required: true
        },
        totale_uren: {
            value: '',
            required: true
        },
        opmerking: {
            value: '',
            required: false
        }
    });
    const [items, setItems] = useState({
            value: [{
                onderdeel: '',
                soort: '',
                merk: '',
                aantal: '',
                stukprijs: ''
            }],
            required: false
    });

    const isFormValid = (obj) => {
        const requiredFields = Object.keys(obj).filter(key => obj[key].required);
        return requiredFields.every(key => obj[key].value !== '');
    }

    const handleChange = (section, field, value) => {
        if (section === 'gegevens') {
            const current = {...gegevens};
            current[field].value = value;
            setGegevens(current);
            setGegevensValid(isFormValid(current));
        }
    }

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setBestand(file);
    };

    const handleItemsChange = (field, value, index) => {
        const current = {...items};
        current.value[index][field] = value;
        setItems(current);
        const totalPrice = items.value.reduce((total, item) => {
            const itemPrice = item.aantal * item.stukprijs;
            return total + itemPrice;
        }, 0);  
        setTotalPrice(totalPrice);
    }

    const handleItems = (type) => {
        const current = {...items};
        const obj = {
            onderdeel: '',
            soort: '',
            merk: '',
            aantal: '',
            stukprijs: ''
        }
        if (type === 'add') {
            current.value.push(obj);
        } else {
            current.value.pop();
        }
        setItems(current);
    }

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const getSchade = async () => {
        setIsLoading(true);
        try {
            const response = await api.get('/schades/reparateur/' + uuid);
            if (response.data) {
                if (response.data.length > 0) {
                    setSchade(response.data[0]);
                } else {
                    setNothingFound(true);
                }
                setIsLoading(false);
            }
        } catch (error) {
            if (!error.response) {
                return navigate('/offline');
            }
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                  break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }

    const assignDocument = async (filename) => {
        try {
            const response = await api.post('/schades/werkbon-reparateur/' + uuid, {
                data: {
                    documentnaam: 'Taxatie ' + gegevens.reparateur.value,
                    pad: filename,
                    werkbon: true,
                    oude_versie: false
                }
            });
            if (response.data) {
                setCreatingDossier(false);
                setIsSubmitted(true);
            }
        } catch (error) {}
    }

    const submitOwnDocument = async (event) => {
        event.preventDefault();
        await uploadDocument(bestand, 'existing');
    }
    
    const uploadDocument = async (file, type) => {
        setCreatingDossier(true);
        await delay(3500);
        var formData = new FormData();
        if (type === 'new') {
            formData.append("files", file, 'taxatie-schade-' + schade.kenmerk + '.pdf');
        } else {
            formData.append("files", file, file.name);
        }
        try {
            const response = await api.post('/schades/upload-werkbon-reparateur/' + uuid + '/' + schade.kenmerk, formData, {
                headers: { 
                    "Content-Type": "multipart/form-data"
                }
            });
            if (response.data) {
                const path = response.data.filepaths[0];
                const fileName = path.match(/[^\\]+$/)[0];
                assignDocument(fileName);
            }
        } catch (error) {
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                  break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }

    //Check if user is authenticated, else redirect to login
    useEffect(() => {
        getSchade();
    }, []);
    
    return (
    <div id="main" className="dashboard">

        <div className="dashboard-content werkbon-reparateur">
            
            
            {
                isLoading ?
                <p>Laden dossier...</p>
                :
                <>
                {
                    nothingFound && !isSubmitted ?
                    <p>Helaas niks gevonden...</p>
                    :
                    <>
                    {
                        isSubmitted ?
                        <p>Taxatie is aangemaakt en ingestuurd</p>
                        :
                        <>
                        <DashboardHeader title={'Kostenindicatie schade ' + schade.kenmerk + ' | ' + schade.kenteken} hideLogout={true} />
                        <div className="werkbon-content">
                            <div className="werkbon-formuier">
                                <div className="block">
                                    <div className="tabs">
                                        <div className={'tab ' + (!uploadOwnDocument ? 'active' : '')} onClick={() => setUploadOwnDocument(false)}><div className="tab-content">Werkbon aanmaken</div></div>
                                        <div className={'tab ' + (uploadOwnDocument ? 'active' : '')} onClick={() => setUploadOwnDocument(true)}><div className="tab-content">Upload je eigen document</div></div>
                                    </div>
                                    {
                                        !uploadOwnDocument ?
                                        <div className="content">
                                            <h3>Prijsopgave</h3>
                                            <input type="text" placeholder="Naam reparateur" className={gegevens.reparateur.value ? 'form-field valid' : 'form-field'} value={gegevens.reparateur.value} onChange={(e) => handleChange('gegevens', 'reparateur', e.target.value)} disabled={isLoading} />
                                            <div className="two-column">
                                                <input type="date" name="datum" placeholder="Datum (dd-mm-jjjj)" className={gegevens.datum.value ? 'form-field valid' : 'form-field'} value={gegevens.datum.value} onChange={(e) => handleChange('gegevens', 'datum', e.target.value)} disabled={isLoading} />
                                                <input type="text" placeholder="Soort voertuig" className={gegevens.soort_voertuig.value ? 'form-field valid' : 'form-field'} value={gegevens.soort_voertuig.value} onChange={(e) => handleChange('gegevens', 'soort_voertuig', e.target.value)} disabled={isLoading} />
                                            </div>
                                            <div className="three-column">
                                                <input type="text" placeholder="Monteur" className={gegevens.monteur.value ? 'form-field valid' : 'form-field'} value={gegevens.monteur.value} onChange={(e) => handleChange('gegevens', 'monteur', e.target.value)} disabled={isLoading} />
                                                <input type="number" placeholder="Uurloon" className={gegevens.uurloon.value ? 'form-field valid' : 'form-field'} value={gegevens.uurloon.value} onChange={(e) => handleChange('gegevens', 'uurloon', e.target.value)} disabled={isLoading} />
                                                <input type="number" placeholder="Totale uren" className={gegevens.totale_uren.value ? 'form-field valid' : 'form-field'} value={gegevens.totale_uren.value} onChange={(e) => handleChange('gegevens', 'totale_uren', e.target.value)} disabled={isLoading} />
                                            </div>
                                            <div className="werkbon-items">
                                                {
                                                items.value.map((item, index) =>  {
                                                        return <div key={index}>
                                                            <div className="fields">
                                                                <div className="two-column">
                                                                    <input type="text" placeholder="Onderdeel" className={item.onderdeel ? 'form-field valid' : 'form-field'} value={item.onderdeel} onChange={(e) => handleItemsChange('onderdeel', e.target.value, index)} disabled={isLoading} />
                                                                    <input type="text" placeholder="Soort" className={item.soort ? 'form-field valid' : 'form-field'} value={item.soort} onChange={(e) => handleItemsChange('soort', e.target.value, index)} disabled={isLoading} />
                                                                </div>
                                                                <div className="three-column">
                                                                    <input type="text" placeholder="Merk" className={item.merk ? 'form-field valid' : 'form-field'} value={item.merk} onChange={(e) => handleItemsChange('merk', e.target.value, index)} disabled={isLoading} />
                                                                    <input type="number" placeholder="Aantal" className={item.aantal ? 'form-field valid' : 'form-field'} value={item.aantal} onChange={(e) => handleItemsChange('aantal', e.target.value, index)} disabled={isLoading} />
                                                                    <input type="number" placeholder="Stukprijs" className={item.stukprijs ? 'form-field valid' : 'form-field'} value={item.stukprijs} onChange={(e) => handleItemsChange('stukprijs', e.target.value, index)} disabled={isLoading} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }) 
                                                }
                                                <div className="actions">
                                                    {
                                                        items.value.length > 1 ?
                                                        <button className="remove" onClick={() => handleItems('remove')}><Icon.DashCircleFill /></button>
                                                        :
                                                        null
                                                    }  
                                                    <button className="add" onClick={() => handleItems('add')}><Icon.PlusCircleFill /></button>
                                                </div>
                                            </div>
                                            <textarea className="form-field" placeholder="Eventuele opmerking" value={gegevens.opmerking.value} onChange={(e) => handleChange('gegevens', 'opmerking', e.target.value)}></textarea>
                                        </div>
                                        :
                                        <div className="content">
                                            <h3>Eigen document uploaden</h3>
                                            <form onSubmit={submitOwnDocument}>
                                                <input type="text" placeholder="Naam reparateur" className={gegevens.reparateur.value ? 'form-field valid' : 'form-field'} value={gegevens.reparateur.value} onChange={(e) => handleChange('gegevens', 'reparateur', e.target.value)} disabled={isLoading} required={true} />
                                                <div className="bestand">
                                                    <span className="label">Bestand toevoegen (.XLS, .XLSX, .PDF, .DOC, .DOCX)</span>
                                                    <input
                                                        type="file"
                                                        name="bestand"
                                                        id="file-upload"
                                                        placeholder="Bestand"
                                                        accept=".xls,.xlsx,application/pdf,.doc,.docx"
                                                        onChange={(e) => handleFileUpload(e)}
                                                        className="form-field file"
                                                        required={true}
                                                        disabled={isLoading}
                                                    />
                                                </div>
                                                <input type="submit" value="Eigen document uploaden" className="submit-form" disabled={isLoading} />
                                            </form>
                                        </div>
                                    }
                                    
                                </div>
                            </div>


                            <div className="werkbon-overzicht">
                                <div className="block">
                                    <div className="content">
                                        <div className="block-header">
                                            <h3>Foto's</h3>
                                            <div className="icons">
                                                <Icon.Camera />
                                            </div>
                                        </div>
                                        <SchadeFotos schade={schade} setShowDocument={setShowDocument} setIframePdf={setIframePdf} setDocumentTitle={setDocumentTitle} setImageUrl={setImageUrl} />
                                    </div>
                                </div>

                                {
                                    !uploadOwnDocument ?
                                    <div className="block">
                                        <div className="content">
                                            <div className="block-header">
                                                <h3>Totalen</h3>
                                            </div>
                                            <table className="werkbon-totals">
                                                <thead>
                                                <tr>
                                                    <th>Onderdeel</th>
                                                    <th>Merk</th>
                                                    <th>Aantal</th>
                                                    <th style={{textAlign: 'right'}}>Stukprijs</th>
                                                    <th style={{textAlign: 'right'}}>Totaalprijs</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        items.value.map((item, index) =>  {
                                                            if (!Object.values(item).every(value => value === '')) {
                                                                return <tr key={index}>
                                                                    <td><p>{item.onderdeel}</p><span>{item.soort}</span></td>
                                                                    <td>{item.merk}</td>
                                                                    <td>{item.aantal}</td>
                                                                    <td style={{textAlign: 'right'}}>{item.stukprijs ? '€ ' + formatBedrag(item.stukprijs) : ''}</td>
                                                                    <td style={{textAlign: 'right'}}>{item.stukprijs && item.aantal ? '€ ' + (formatBedrag(item.stukprijs * item.aantal)) : ''}</td>
                                                            </tr>
                                                            }
                                                        })
                                                    }
                                                    {
                                                        gegevens.uurloon.value && gegevens.totale_uren.value ?
                                                        <tr>
                                                            <td>Totale uren</td>
                                                            <td></td>
                                                            <td>{gegevens.totale_uren.value}</td>
                                                            <td style={{textAlign: 'right'}}>{'€ ' + formatBedrag(gegevens.uurloon.value)}</td>
                                                            <td style={{textAlign: 'right'}}>{'€ ' + formatBedrag(gegevens.uurloon.value * gegevens.totale_uren.value)}</td>
                                                        </tr>
                                                        :
                                                        null
                                                    }
                                                </tbody>
                                            </table>
                                            <div className="total-price">
                                                <p>Totaal</p>
                                                <p>{'€ ' + formatBedrag(totalPrice + (gegevens.uurloon.value * gegevens.totale_uren.value))}</p>
                                            </div>
                                            <p className="opmerking">{gegevens.opmerking.value}</p>
                                            {
                                                schade && items ?
                                                <PDFDownloadLink className="example-document" document={<MyDocument kenmerk={schade.kenmerk} kenteken={schade.kenteken} bedrijf={schade.companyName} items={items} gegevens={gegevens} totaalprijs={totalPrice + (gegevens.uurloon.value * gegevens.totale_uren.value)} />} fileName={'concept-taxatie-schade-' + schade.kenmerk + '.pdf'}>
                                                    {({ blob, url, loading, error }) =>
                                                    loading ? 'Document genereren...' : 'Bekijk voorbeeld van taxatie'
                                                    }
                                                </PDFDownloadLink>
                                                :
                                                null
                                            }
                                            {
                                                gegevensValid ?
                                                <BlobProvider document={<MyDocument kenmerk={schade.kenmerk} kenteken={schade.kenteken} bedrijf={schade.companyName} items={items} gegevens={gegevens} totaalprijs={totalPrice + (gegevens.uurloon.value * gegevens.totale_uren.value)} />}>
                                                    {({ blob, url, loading, error }) => {
                                                    // Do whatever you need with blob here
                                                    return <button id="submit-werkbon" onClick={() => uploadDocument(blob, 'new')}>
                                                            <span>Taxatie insturen</span>
                                                    </button>;
                                                    }}
                                                </BlobProvider>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                    :
                                    null
                                }

                                

                            </div>
                        </div>

                        <div className={'view-document side-preview ' + (showDocument ? 'show' : '')}>
                            <div className="side-preview-header">
                                <h3>{documentTitle}</h3>
                                <Icon.XCircleFill onClick={() => setShowDocument(false)} />
                            </div>
                            <div className="side-preview-content">
                                {
                                    iframePdf ?
                                    <iframe src={`${iframePdf}#view=fitH`} title="view PDF" height="100%" width="100%" />
                                    :
                                    null
                                }
                                {
                                    imageUrl ?
                                    <img src={imageUrl} />
                                    :
                                    null
                                }
                            </div>
                        </div>
                        </>
                    }
                    </>
                }
                </>
            }

            <div className={'blurred ' + (showDocument ? 'show' : '')} onClick={() => {setShowDocument(false);}}></div>
            
            <div className={'loader-dossier ' + (creatingDossier ? 'show' : '')}>
                <div className="loader-content">
                    <Lottie lottieRef={lottieAnimation} autoplay={true} animationData={creatingDossierAnimation} />
                    <p>Taxatie toevoegen aan dossier ...</p>
                </div>
            </div>

            <Toaster />
        </div>
    <img src={svzArtwork} className="artwork" />    
    </div>
    );
};
export default WerkbonReparateur;