import { useRef } from 'react';

//3rd party
import * as Icon from 'react-bootstrap-icons';

//styles
import './fileUpload.css';

const FileUpload = (props) => {

    const fileInputRef = useRef(null);

    function handleClick() {
        fileInputRef.current.value = '';
    }


    return (
        <div className={props.documenten[props.field].value ? 'bestand valid' : 'bestand'}>
            <span className="label">{props.label}</span>
            <input type="file" ref={fileInputRef} name="voorkant-schadeformulier" id="front" placeholder="Bestand" accept={props.accept} onChange={(e) => props.handleChange('documenten', props.field, props.multiple ? e.target.files : e.target.files[0])} className="form-field file" multiple={props.multiple} disabled={props.isLoading} />
            {props.documenten[props.field].value ? <button className="remove-file" onClick={() => {props.handleChange('documenten', props.field, ''); handleClick()}}><Icon.Trash3 /></button> : null}
            {props.required ? <span className="required">Verplicht</span> : null}
        </div>
    );
};
export default FileUpload;