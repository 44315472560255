import { useState, useRef } from 'react';

//3rd party
import * as Icon from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import { saveAs } from "file-saver";

//styles
import './schadeDocumenten.css';
import 'react-loading-skeleton/dist/skeleton.css';


const Example = (props) => <button onClick={() => { props.fetchPdf(props.mime.includes("pdf") ? props.url : ''); props.setShowDocument(true); props.setShowSlider(false); props.setDocumentTitle(props.title); props.setImageUrl(props.mime.includes("image") ? props.url : ''); }}><Icon.ZoomIn /><span>Voorbeeld</span></button>;
const BlobExample = (props) => <button onClick={() => { props.getFileContents(props.folder, props.subfolder, props.filename); props.setShowDocument(true); props.setShowSlider(false); props.setDocumentTitle(props.title); }}><Icon.ZoomIn /><span>Voorbeeld</span></button>;
const Download = (props) => <button onClick={() => { saveAs(props.url, props.name) }}><Icon.Download /><span>Download</span></button>;
const Delete = (props) => <button onClick={() => { props.deleteDocument(props.index) }}><Icon.Trash3 /></button>;

const SchadeDocumenten = (props) => {
    
    const environment = process.env.REACT_APP_APP_URL;
    const [isLoading, setIsloading] = useState(false);
    const fileInputRef = useRef(null);
    function handleClick() {
        fileInputRef.current.value = '';
    }

    return (
        <>
        {
            props.schade ?
            <>
            {
                props.schade ?
                <div className="document-list">
                    {/* {
                    props.schade.voorkant_schadeformulier ?
                        <>
                        {
                            props.schade.schadeformulier ?
                            <div className="item"><span className="document-name">Schadeformulier</span><div className="actions"><Example mime={props.schade.schadeformulier.mime} fetchPdf={props.fetchPdf} url={environment + props.schade.schadeformulier.url} setShowDocument={props.setShowDocument} setShowSlider={props.setShowSlider} title={'Schadeformulier'} setDocumentTitle={props.setDocumentTitle} setImageUrl={props.setImageUrl} /><Download name={'schadeformulier-' + props.schade.kenmerk} url={environment + props.schade.schadeformulier.url} /></div></div>
                            :
                            <>
                            <div className="item"><span className="document-name">Voorkant schadeformulier</span><div className="actions"><Example mime={props.schade.voorkant_schadeformulier.mime} fetchPdf={props.fetchPdf} url={environment + props.schade.voorkant_schadeformulier.url} setShowDocument={props.setShowDocument} setShowSlider={props.setShowSlider} title={'Voorkant schadeformulier'} setDocumentTitle={props.setDocumentTitle} setImageUrl={props.setImageUrl} /><Download name={'voorkant-schadeformulier-' + props.schade.kenmerk} url={environment + props.schade.voorkant_schadeformulier.url} /></div></div>
                            {
                                props.schade.achterkant_schadeformulier ?
                                <div className="item"><span className="document-name">Achterkant schadeformulier</span><div className="actions"><Example mime={props.schade.achterkant_schadeformulier.mime} fetchPdf={props.fetchPdf} url={environment + props.schade.achterkant_schadeformulier.url} setShowDocument={props.setShowDocument} setShowSlider={props.setShowSlider} title={'Achterkant schadeformulier'} setDocumentTitle={props.setDocumentTitle} setImageUrl={props.setImageUrl} /><Download name={'achterkant-schadeformulier-' + props.schade.kenmerk} url={environment + props.schade.achterkant_schadeformulier.url} /></div></div>
                                :
                                null
                            }
                            </> 
                        }
                        </>
                        :
                        null
                    } */}
                    {
                    props.schade.voorkant_schadeformulier_fs ?
                        <>
                        {
                            props.schade.schadeformulier ?
                            <div className="item"><span className="document-name">Schadeformulier</span><div className="actions"><Example mime={props.schade.schadeformulier.mime} fetchPdf={props.fetchPdf} url={environment + props.schade.schadeformulier.url} setShowDocument={props.setShowDocument} setShowSlider={props.setShowSlider} title={'Schadeformulier'} setDocumentTitle={props.setDocumentTitle} setImageUrl={props.setImageUrl} /><Download name={'schadeformulier-' + props.schade.kenmerk} url={environment + props.schade.schadeformulier.url} /></div></div>
                            :
                            <>
                            <div className="item"><span className="document-name">Voorkant schadeformulier FS</span>
                                <div className="actions">
                                    {
                                        props.schade.voorkant_schadeformulier_fs.includes('.pdf') ?
                                        <BlobExample folder={props.schade.kenmerk} subfolder={'Documenten'} filename={props.schade.voorkant_schadeformulier_fs} getFileContents={props.getFileContents} setShowDocument={props.setShowDocument} setShowSlider={props.setShowSlider} title={'Voorkant schadeformulier'} />
                                        :
                                        null
                                    }
                                    <button onClick={() => props.handleDownload(props.schade.kenmerk, 'Documenten', props.schade.voorkant_schadeformulier_fs)}>Download</button>
                                    </div>
                                </div>
                            {
                                props.schade.achterkant_schadeformulier_fs ?
                                <div className="item"><span className="document-name">Achterkant schadeformulier FS</span>
                                    <div className="actions">
                                        {
                                            props.schade.achterkant_schadeformulier_fs.includes('.pdf') ?
                                            <BlobExample folder={props.schade.kenmerk} subfolder={'Documenten'} filename={props.schade.achterkant_schadeformulier_fs} getFileContents={props.getFileContents} setShowDocument={props.setShowDocument} setShowSlider={props.setShowSlider} title={'Achterkant schadeformulier'} />
                                            :
                                            null
                                        }
                                        <button onClick={() => props.handleDownload(props.schade.kenmerk, 'Documenten', props.schade.achterkant_schadeformulier_fs)}>Download</button>
                                    </div>
                                </div>
                                :
                                null
                            }
                            </> 
                        }
                        </>
                        :
                        null
                    }
                    {/* {
                        props.schade.documenten ?
                        props.schade.documenten.map((item, index) =>  {
                            if (item.document) {
                                const documentName = item.documentnaam + '-' + props.schade.kenmerk + '' + item.document.ext;
                                if (props.schade.status.key === 'in_afwachting_wagenpark' && item.werkbon && item.oude_versie) {
    
                                } else {
                                    return <div key={index} className="item"><span className="document-name">{item.documentnaam}<span>{item.oude_versie ? 'Oude versie' : ''}</span></span>
                                    <div className="actions">{!item.document.mime.includes("pdf") && !item.document.mime.includes("image") ? null : <Example mime={item.document.mime} fetchPdf={props.fetchPdf} url={environment + item.document.url} setShowDocument={props.setShowDocument} setShowSlider={props.setShowSlider} title={item.documentnaam} setDocumentTitle={props.setDocumentTitle} setImageUrl={props.setImageUrl} />}<Download name={documentName.replace(/\s+/g, '-').toLowerCase()} url={environment + item.document.url} />
                                    {
                                        !item.werkbon ?
                                        <Delete deleteDocument={props.deleteDocument} index={index} />
                                        :
                                        null
                                    }
                                    
                                    </div></div>
                                }
                            }
                        })
                        :
                        null
                    } */}
                    {
                        props.schade.documenten ?
                        props.schade.documenten.map((item, index) =>  {
                            if (props.schade.status.key === 'in_afwachting_wagenpark' && item.werkbon && item.oude_versie) {

                            } else {
                                return <div key={index} className="item"><span className="document-name">{item.documentnaam}<span>{item.oude_versie ? 'Oude versie' : ''}</span></span>
                                <div className="actions">
                                {
                                    item.pad && item.pad.includes('.pdf') ?
                                    <BlobExample folder={props.schade.kenmerk} subfolder={'Documenten'} filename={item.pad} getFileContents={props.getFileContents} setShowDocument={props.setShowDocument} setShowSlider={props.setShowSlider} title={item.documentnaam} />
                                    :
                                    null
                                }
                                <button onClick={() => props.handleDownload(props.schade.kenmerk, 'Documenten', item.pad)}>Download</button>
                                {
                                    !item.werkbon ?
                                    <Delete deleteDocument={props.deleteDocument} index={index} />
                                    :
                                    null
                                }
                                
                                </div></div>
                            }
                        })
                        :
                        null
                    }
                </div>
                :
                <p>Geen documenten beschikbaar</p>
            }
            
            </>
            :
            <>
            <Skeleton height={20} style={{marginBottom:10}} />
            <Skeleton height={20} style={{marginBottom:10}} />
            <Skeleton height={20} style={{marginBottom:10}} />
            <Skeleton height={20} style={{marginBottom:10}} />
            </>
        }
        
        </>
    );
};
export default SchadeDocumenten;