import { useState, useRef, useEffect } from 'react';

//3rd party
import * as Icon from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import { Tooltip } from 'react-tooltip';

//styles
import './schadeCommunicatie.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-tooltip/dist/react-tooltip.css';

const SchadeCommunicatie = (props) => {

    const scrollContainerRef = useRef(null);
    const communicatie = props.schade.communicatie;
    const [chatDatum, setChatDatum] = useState('');
    const [showFaded, setShowFaded] = useState(false);
    var odd_even = 'even';
    const offset = 120;

    useEffect(() => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
        }
        function handleScroll() {
            if (scrollContainerRef.current) {
              // Calculate the scroll difference from the bottom
              const scrollDifference = scrollContainerRef.current.scrollHeight - scrollContainerRef.current.scrollTop - scrollContainerRef.current.clientHeight;
      
              // Check if the scroll difference is more than 100px
              if (scrollDifference > 10) {
                setShowFaded(true);
              } else {
                setShowFaded(false);
              }

              const scrollPosition = scrollContainerRef.current.scrollTop;

                // Find the first message that is at or above the top of the visible area
                const chatMessages = scrollContainerRef.current.querySelectorAll('.message');
                let closestMessageDate = '';

                for (let i = 0; i < chatMessages.length; i++) {
                const message = chatMessages[i];
                const rect = message.getBoundingClientRect();

                if (rect.top <= scrollPosition + offset) {
                    closestMessageDate = message.querySelector('.datetime').textContent;
                } else {
                    break; // Stop checking once we find a message below the top of the visible area
                }
                }
                if (closestMessageDate) {
                    setChatDatum(moment(closestMessageDate).format('DD-MM-YYYY'));
                }

            }
          }
      
          // Attach the scroll event listener to the scroll container
          scrollContainerRef.current?.addEventListener('scroll', handleScroll);
      
          // Clean up the event listener when the component unmounts
          return () => {
            scrollContainerRef.current?.removeEventListener('scroll', handleScroll);
          };
      }, [props.schade]);


    return (
        <>
        {
            props.schade ?
            <div className="chatbox">
            <div className={'faded-chat ' + (showFaded ? '' : 'hide')}>
                <span>{chatDatum}</span>
            </div>
            {
                communicatie.length > 0 ?
                <div className="chat" ref={scrollContainerRef}>
                    {
                        communicatie.map((item, index) =>  {
                            if (index > 0) {
                                if (item.door === communicatie[index - 1].door) {} else {
                                    if (odd_even === 'even') {
                                        odd_even = 'odd';
                                    } else {
                                        odd_even = 'even';
                                    }
                                }
                            }
                            return <div key={index} className={'message ' + odd_even}>
                                <div className="message-content">
                                    <div className="message-header">
                                        <span>{item.door}</span>
                                        <span className="date">
                                            {
                                                item.mail_verstuurd ?
                                                <>
                                                <span className="mail" id={'mail-send-' + index}><Icon.EnvelopeCheckFill color="#CB0A32" /></span>
                                                <Tooltip
                                                    anchorId={'mail-send-' + index}
                                                    place="bottom"
                                                    content="Mail van opmerking verstuurd"
                                                />
                                                </>
                                                :
                                                null
                                            }
                                            {moment(item.datum_tijd).format('DD-MM-YYYY HH:mm')}
                                        </span>
                                        <span className="datetime">{item.datum_tijd}</span>
                                    </div>
                                    <span className="contents">{item.bericht}</span>
                                </div>
                            </div>
                        })
                    }
                </div>
                :
                <p>Er zijn nog geen opmerkingen geplaatst</p>
            }
            </div>
            :
            <>
            <Skeleton height={20} style={{marginBottom:10}} />
            <Skeleton height={20} style={{marginBottom:10}} />
            <Skeleton height={20} style={{marginBottom:10}} />
            <Skeleton height={20} style={{marginBottom:10}} />
            </>
        }
        </>
    );
};
export default SchadeCommunicatie;