import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

//Helper functions
import { getSettings, notifyError, notifySuccess, getTimeFromSeconds, countTotalSeconds } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import toast, { Toaster } from 'react-hot-toast';
import * as Icon from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import DataTable from 'react-data-table-component';
import { ThreeDots } from  'react-loader-spinner';
import Rodal from 'rodal';
import YouTube from 'react-youtube';
import Lottie from "lottie-react";

//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';
import Registratiedag from '../../../components/registreren/registratiedag';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';
import svzArtwork from '../../../assets/images/artwork-svz.svg';
import savedAnimation from "../../../assets/animations/checkmark.json";

//styles
import './registraties-controleren.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'rodal/lib/rodal.css';

const Status = (props) => {
    if (props.status === 'deels_ingestuurd') {
        var status = 'Uren ' + props.month + ' ingestuurd';
    } else if (props.status === 'deels_goedgekeurd') {
        var status = 'Deels goegekeurd (' + props.month + ')';
    } else if (props.status === 'deels_goedgekeurd_def') {
        var status = 'Deels definitief goedgekeurd'
    } else if (props.status === 'deels_verwerkt') {
        var status = 'Deels verwerkt';
    } else {
        var status = props.status;
    }
    return <p className={props.status + ' status'}>{status}</p>;
}
const Bekijken = (props) => <button className="view" onClick={() => {props.setCurrentId(props.id); props.setOverzicht(false); props.loadRegistratieById(props.registraties, props.id)}}>Bekijken</button>

const RegistratiesControleren = () => {

    //Set variables
    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const medewerker = JSON.parse(localStorage.getItem('medewerker'));
    const videotutorials = JSON.parse(localStorage.getItem('videotutorials'));
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paramWeek = queryParams.get('week');
    const paramYear = queryParams.get('jaar');
    const paramDivisie = queryParams.get('divisie');
    const [showModalHelp, setShowModalHelp] = useState(false);
    const [showModalOpmerking, setShowModalOpmerking] = useState(false);
    const [opmerking, setOpmerking] = useState('');
    const [sendingOpmerking, setSendingOpmerking] = useState(false);
    const [showModalLogs, setShowModalLogs] = useState(false);
    const [overzicht, setOverzicht] = useState(true);
    const [currentId, setCurrentId] = useState(0);
    const [listRegistraties, setListRegistraties] = useState([]);
    const [registraties, setRegistraties] = useState([]);
    const [allRegistraties, setAllRegistraties] = useState([]);
    const [totalHours, setTotalHours] = useState('00:00');
    const [verlofHours, setVerlofHours] = useState('00:00');
    const [contractHours, setContractHours] = useState('00:00');
    const [vastSalaris, setVastSalaris] = useState(false);
    const [logs, setLogs] = useState([]);
    const [urenTypes, setUrenTypes] = useState([]);
    const arrayDays = ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'];
    const [year, setYear] = useState(paramYear ? paramYear : moment().format('YYYY'));
    const [currentMonth, setCurrentMonth] = useState(moment().format('MMMM'));
    const [weekNumber, setWeekNumber] = useState(paramWeek ? Number(paramWeek) : moment().isoWeek() - 1);
    const [divisies, setDivisies] = useState([]);
    const [divisie, setDivisie] = useState(paramDivisie ? Number(paramDivisie) : 0);
    const [standplaatsen, setStandplaatsen] = useState([]);
    const [standplaats, setStandplaats] = useState(0);
    const [types, setTypes] = useState([]);
    const [type, setType] = useState(0);
    const [nextRegistratie, setNextRegistratie] = useState(0);
    const [previousRegistratie, setPreviousRegistratie] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const youtubePlayer = useRef(null);
    const opts = {
        height: '280',
        width: '500',
        playerVars: {
          autoplay: 0,
        },
    };
    const [showSaving, setShowSaving] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      };

    //Function to do custom sort on status because it is wrapped in <p> tag
    const customStatusSort = (rowA, rowB) => {
        const a = rowA.status.props.status;
        const b = rowB.status.props.status;
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };

    //Columns for datatable
    const columns = [
        {
            name: 'Naam',
            selector: row => row.naam,
            sortable: true,
        },
        {
            name: 'Personeelsnummer',
            selector: row => row.personeelsnummer,
            sortable: true,
            hide: 'md'
        },
        // {
        //     name: 'Telefoonnummer',
        //     selector: row => row.telefoonnummer,
        //     hide: 'md'
        // },
        {
            name: 'Controleur',
            selector: row => row.controleur,
            sortable: true,
            hide: 'md'
        },
        {
            name: 'Controleur twee',
            selector: row => row.controleur_twee,
            sortable: true,
            hide: 'md'
        },
        {
            name: 'Divisie',
            selector: row => row.divisie,
            sortable: true,
            hide: 'md'
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            sortFunction: customStatusSort
        },
        {
            name: '',
            selector: row => row.bekijken
        }
    ];
    //Translations for datatable
    const paginationComponentOptions = {
        rowsPerPageText: 'Rijen per pagina',
        rangeSeparatorText: 'van',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Selecteer alles',
    };
    

    const handleTotalHours = (uren) => {
        var arrayDays = [uren.zondag, uren.maandag, uren.dinsdag, uren.woensdag, uren.donderdag, uren.vrijdag, uren.zaterdag];
        var seconds = 0;
        arrayDays.map((item, index) =>  {
            seconds += countTotalSeconds(item);
        });
        setTotalHours(getTimeFromSeconds(seconds));
    }

    const getUrenTypes = async () => {
        try {
            const response = await api.get('/urentypes');
            if (response.data) {
                setUrenTypes(response.data);
            }
        } catch (error) {}
    }

    const loadRegistratieById = (arrayRegistraties, id) => {
        const result = arrayRegistraties.find(item => item.id === id);
        if (!result) {
            setRegistraties([]);
        } else {
            setRegistraties(result);
            setCurrentMonth(moment(result.maandag.datum).format('MMMM'));
        }
        setContractHours(result.medewerker.contracturen);
        setVastSalaris(result.medewerker.vast_salaris);
        handleTotalHours(result);
        setLogs(result.logs);
        setCurrentId(result.id);
        setRegistraties(result);
        const index = arrayRegistraties.findIndex(item => item.id === id);
        setNextRegistratie(arrayRegistraties[index + 1]);
        setPreviousRegistratie(arrayRegistraties[index - 1]);
        setOpmerking('');
    }

    //Function to get registraties
    const getRegistraties = async () => {
        if (!weekNumber) {
            notifyError('Vul een weeknummer in');
            return;
        }
        if (Number(weekNumber) < 1 || Number(weekNumber) > 52) {
            notifyError('Kies een weeknummer van 1 t/m 52');
            return;
        }
        if (!year) {
            notifyError('Vul een jaar in');
            return;
        }
        if (year.length !== 4) {
            notifyError('Vul een geldig jaartal in');
            return;
        }
        setIsLoading(true);
        try {
            const response = await api.get('/registraties?week=' + weekNumber + '&year=' + year + '&divisie=' + divisie + '&standplaats=' + standplaats + '&type=' + type);
            if (response.data) {
                //var filteredArray = response.data[0].registraties;
                var filteredArray = response.data[0].registraties.filter(function (item) {

                    const controleur_vervangen = item.medewerker.controleur_uren.controle_naar_vervangende_controleur;
                    const controleur_id = controleur_vervangen ? item.medewerker.controleur_uren.vervangende_controleur.id : item.medewerker.controleur_uren.id;
                    const controleur_twee_vervangen = item.medewerker.controleur_twee_uren.controle_naar_vervangende_controleur;
                    const controleur_twee_id = controleur_twee_vervangen ? item.medewerker.controleur_twee_uren.vervangende_controleur.id : item.medewerker.controleur_twee_uren.id;
                    
                    if ((item.status === 'deels_ingestuurd' || item.status === 'ingestuurd') && (controleur_id === medewerker.id || controleur_twee_id === medewerker.id)) {
                        return true;
                    }
                    if (item.status === 'ingestuurd' && (controleur_id === medewerker.id || controleur_twee_id === medewerker.id)) {
                        return true;
                    }
                    if ((item.status === 'deels_goedgekeurd' || item.status === 'goedgekeurd') && controleur_twee_id === medewerker.id) {
                        return true;
                    }
                    if ((item.status === 'deels_goedgekeurd_def') && (controleur_id === medewerker.id || controleur_twee_id === medewerker.id)) {
                        return true;
                    }
                    if (item.status === 'open') {
                        return true;
                    }
                    //return item.status !== 'open';
                });
                setAllRegistraties(filteredArray);
                var arrayData = [];
                response.data[0].registraties.map((item, index) =>  {
                    const controleur_vervangen = item.medewerker.controleur_uren.controle_naar_vervangende_controleur;
                    const controleur_id = controleur_vervangen ? item.medewerker.controleur_uren.vervangende_controleur.id : item.medewerker.controleur_uren.id;
                    const controleur_voornaam = controleur_vervangen ? item.medewerker.controleur_uren.vervangende_controleur.voornaam : item.medewerker.controleur_uren.voornaam;
                    const controleur_twee_vervangen = item.medewerker.controleur_twee_uren.controle_naar_vervangende_controleur;
                    const controleur_twee_id = controleur_twee_vervangen ? item.medewerker.controleur_twee_uren.vervangende_controleur.id : item.medewerker.controleur_twee_uren.id;
                    const controleur_twee_voornaam = controleur_twee_vervangen ? item.medewerker.controleur_twee_uren.vervangende_controleur.voornaam : item.medewerker.controleur_twee_uren.voornaam;
                    const obj = {
                        id: item.id,
                        naam: item.medewerker.voornaam + ' ' + item.medewerker.achternaam,
                        personeelsnummer: item.medewerker.personeelsnummer,
                        controleur: item.medewerker.controleur_uren.voornaam + ' ' + item.medewerker.controleur_uren.achternaam,
                        controleur_twee: item.medewerker.controleur_twee_uren.voornaam + ' ' + item.medewerker.controleur_twee_uren.achternaam,
                        divisie: item.medewerker.divisie.naam,
                        //telefoonnummer: item.medewerker.telefoonnummer,
                        status: item.status === 'goedgekeurd' && controleur_twee_id !== medewerker.id || item.status === 'deels_goedgekeurd' && controleur_twee_id !== medewerker.id ? <Status status={'Ter controle bij ' + controleur_twee_voornaam} /> : <Status status={item.status} month={moment(item.maandag.datum).format('MMMM')} />,
                        bekijken: (item.status === 'open' || item.status === 'ingestuurd' || item.status === 'deels_ingestuurd' || item.status === 'deels_goedgekeurd_def') || (item.status === 'goedgekeurd' && controleur_twee_id === medewerker.id || item.status === 'deels_goedgekeurd' && controleur_twee_id === medewerker.id) ? <Bekijken id={item.id} setCurrentId={setCurrentId} setOverzicht={setOverzicht} registraties={filteredArray} loadRegistratieById={loadRegistratieById} /> : null
                    };
                    arrayData.push(obj);
                })
                setListRegistraties(arrayData);
                setIsLoading(false);
            }
        } catch (error) {
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                  break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }

    const submitRegistratie = async (status) => {
        // if (status === 'deels_ingestuurd') {
        //     var successResponse = 'Uren ' + currentMonth + ' zijn goedgekeurd';
        //     var newStatus = 'deels_goedgekeurd';
        // } else {
        //     var newStatus = 'goedgekeurd';
        //     if (registraties.medewerker.loonperiode === 'per_maand' && registraties.maanden === 'two') {
        //         var successResponse = 'Overige uren zijn goedgekeurd';
        //     } else {
        //         var successResponse = 'Uren zijn goedgekeurd';
        //     }
        // }
        var successResponse = 'Uren zijn goedgekeurd';
        var registratieData = {
            "data": {
                "id": currentId,
                "weeknummer": registraties.weeknummer,
                "jaar": registraties.jaar,
                "status": status,
                "maanden": registraties.maanden
            }
        };
        arrayDays.map((item, index) =>  {
            registratieData.data[item] = {
                "datum": registraties[item].datum,
                "uren_volgens_rooster": registraties[item].uren_volgens_rooster,
                "kilometers": registraties[item].kilometers,
                "feestdag": registraties[item].feestdag,
                "begintijd": registraties[item].begintijd,
                "eindtijd": registraties[item].eindtijd,
                "pauze": registraties[item].pauze,
                "urentype": registraties[item].urentype,
                "diensturen": registraties[item].diensturen,
                "correctie": registraties[item].correctie,
                "overuren": registraties[item].overuren,
                "opmerking": registraties[item].opmerking,
                "storingsdienst": registraties[item].storingsdienst
            }
        });
        setShowSaving(true);
        try {
            const response = await toast.promise(
                api.put('/registraties/:id', registratieData),
                {
                    loading: 'Opslaan...',
                    success: <b>{successResponse}</b>,
                    error: <b>Er is iets misgegaan met het opslaan. Probeer het later nog eens of neem contact op met ICT.</b>,
                }
            );
            if (response.data) {
                var filteredArray = allRegistraties.filter(function (item) {
                    return item.id !== currentId;
                });
                setAllRegistraties(filteredArray);
                if (nextRegistratie) {
                    loadRegistratieById(filteredArray, nextRegistratie.id);
                } else if (previousRegistratie) {
                    loadRegistratieById(filteredArray, previousRegistratie.id);
                } else {
                    getRegistraties();
                    setOverzicht(true);
                }
                scrollToTop();
                setTimeout(function() {
                    setShowSaving(false);
                }, 1000)
            }
        } catch (error) {
            setShowSaving(false);
        }
    }

    const sendOpmerking = async (event) => {
        event.preventDefault();
        setSendingOpmerking(true);
        try {
            const response = await api.post('/registraties/mail-medewerker', {
                "data": {
                    "weeknummer": registraties.weeknummer,
                    "ontvanger": registraties.medewerker.gebruiker.email,
                    "opmerking": opmerking
                }
            });
            if (response.data) {
                setShowModalOpmerking(false);
                setOpmerking('');
                setSendingOpmerking(false);
                notifySuccess('Opmerking is verstuurd!');
            }
        } catch (error) {
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                  break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }

    const getOptions = async (route, state) => {
        const itemsArray = [];
        try {
            const response = await api.get(route);
            if (response.data) {
                response.data.data.map((item, index) =>  {
                    const arrayItem = {
                        id: item.id,
                        name: item.attributes.naam
                    };
                    itemsArray.push(arrayItem);
                });
                state(itemsArray);
            }
        } catch (error) {
            if (!error.response) {
                return navigate('/offline');
            }
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            getRegistraties();
        }
      };


    //Check if user is authenticated and else redirect to login, else get registraties
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        if (medewerker.gebruiker.role.type !== 'controleur' && medewerker.gebruiker.role.type !== 'wagenpark_en_controleur' && medewerker.gebruiker.role.type !== 'loonadministratie' && medewerker.gebruiker.role.type !== 'super_admin') {
            if (medewerker.is_vervanger === false) {
                navigate('/registreren');
            }
        }
        getOptions('/divisies', setDivisies);
        getOptions('/standplaatsen', setStandplaatsen);
        getOptions('/types', setTypes);
        getRegistraties();
        getUrenTypes();
    }, []);
    
    return (
    <div id="main" className="dashboard">
        <SideNav page="/uren-controleren" />
        <div className="dashboard-content">
            <DashboardHeader title="Uren controleren" />
            {
                videotutorials.uren_controleren ?
                <button className="help-button" onClick={() => setShowModalHelp(true)}><Icon.QuestionCircle /><span>Help</span></button>
                :
                null
            }
            {
                showSaving ?
                <div className="saved">
                    <ThreeDots 
                        height="80" 
                        width="80" 
                        radius="9"
                        color="#001c43" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
                :
                null
            }
            

            {
                overzicht ?
                <>
                    <div className="controleren-header">
                    <div className="dropdowns">
                        <p>Week</p>
                        <input type="number" placeholder="12" value={weekNumber === 0 ? weekNumber + 1 : weekNumber} onChange={(e) => setWeekNumber(e.target.value)} onKeyDown={handleKeyDown} />
                        <p>-</p>
                        <input type="number" placeholder={year} value={year} onChange={(e) => setYear(e.target.value)} onKeyDown={handleKeyDown} />
                        {
                            divisies.length > 0 ?
                            <select defaultValue={divisie} onChange={e => setDivisie(e.target.value)}>
                                <option value="0">Alle divisies</option>
                                {
                                    divisies.map((item, index) =>  {
                                        return <option key={index} value={item.id}>{item.name}</option>
                                    })
                                }
                            </select>
                            :
                            null
                        }
                        {
                            standplaatsen.length > 0 ?
                            <select defaultValue={standplaats} onChange={e => setStandplaats(e.target.value)}>
                                <option value="0">Alle standplaatsen</option>
                                {
                                    standplaatsen.map((item, index) =>  {
                                        return <option key={index} value={item.id}>{item.name}</option>
                                    })
                                }
                            </select>
                            :
                            null
                        }
                        {
                            types.length > 0 ?
                            <select defaultValue={type} onChange={e => setType(e.target.value)}>
                                <option value="0">Alle types</option>
                                {
                                    types.map((item, index) =>  {
                                        return <option key={index} value={item.id}>{item.name}</option>
                                    })
                                }
                            </select>
                            :
                            null
                        }
                        <button disabled={isLoading} onClick={() => getRegistraties()}>Registraties ophalen</button>
                    </div>
                </div>

                {
                    isLoading ?
                    <div className="loader-registraties">
                    <ThreeDots 
                        height="80" 
                        width="80" 
                        radius="9"
                        color="#001c43" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                    </div>
                    :
                    <>
                    {
                        listRegistraties.length > 0 ?
                        <div className="registraties">
                            <DataTable
                                columns={columns}
                                data={listRegistraties}
                                pagination
                                paginationComponentOptions={paginationComponentOptions}
                                paginationPerPage={50}
                                fixedHeader
                                //defaultSortFieldId={5}
                            />
                        </div>
                        :
                        <h3>Er zijn geen registraties gevonden</h3>
                    }
                    </>
                }   
                </>
                :
                <>
                    <button className="back-to-list" onClick={() => { setOverzicht(true); getRegistraties() }}><Icon.CardList />Terug naar lijstweergave</button>
                    <div className="registreren-header">
                    {
                        previousRegistratie ?
                        <div className="button-week left">
                            <button onClick={() => loadRegistratieById(allRegistraties, previousRegistratie.id)}><Icon.ArrowLeftCircle /><span>{previousRegistratie.medewerker.voornaam + ' ' + previousRegistratie.medewerker.achternaam}</span></button>
                        </div>
                        :
                        <div className="empty"></div>
                    }
                    <div className="current">
                    <h3>{registraties.medewerker ? registraties.medewerker.voornaam + ' ' + registraties.medewerker.achternaam : ''}</h3>
                    <p className="current-week">Week {registraties.weeknummer}</p>
                    </div>
                    {
                        nextRegistratie ?
                        <div className="button-week right">
                            <button onClick={() => loadRegistratieById(allRegistraties, nextRegistratie.id)}><span>{nextRegistratie.medewerker.voornaam + ' ' + nextRegistratie.medewerker.achternaam}</span><Icon.ArrowRightCircle /></button>
                        </div>
                        :
                        <div className="empty"></div>
                    }
                    
                </div>
                    {
                    registraties && Object.keys(registraties).length ?
                    <div className="days">
                        {
                            arrayDays.map((item, index) =>  {
                                return <Registratiedag 
                                    key={index} 
                                    day={item} 
                                    registraties={registraties}
                                    status={registraties.status}
                                    currentMonth={currentMonth} 
                                    dayInfo={registraties[item]} 
                                    setAllRegistraties={setAllRegistraties} 
                                    allRegistraties={allRegistraties}
                                    urenTypes={urenTypes} 
                                    weekNumber={weekNumber} 
                                    getTimeFromSeconds={getTimeFromSeconds} 
                                    handleTotalHours={handleTotalHours}
                                    type="controleur"
                                    medewerker={medewerker}
                                />
                            })
                        }
                    </div>
                    :
                    <div>
                        <Skeleton height={100} width={'100%'} style={{marginBottom:20}} />
                        <Skeleton height={100} width={'100%'} style={{marginBottom:20}} />
                        <Skeleton height={100} width={'100%'} style={{marginBottom:20}} />
                    </div>
                    }
                    <div className="registreren-footer">
                    <div className="registreren-footer-content">
                        <div className="totals">
                            <table id="info">
                                <tbody>
                                <tr>
                                    <td>Totaal uren:</td>
                                    <td>{totalHours.includes("NaN") ? 'Uren niet correct ingevuld' : totalHours}</td>
                                </tr>
                                <tr>
                                    <td>Contracturen:</td>
                                    <td>{contractHours} {vastSalaris ? '(vast salaris)' : ''}</td>
                                </tr>
                                {
                                    logs.length > 0 ?
                                    <tr className="log">
                                        <td>Laatste update door:</td>
                                        {
                                            logs[logs.length - 1].medewerker ?
                                            <td>{logs[logs.length - 1].medewerker ? logs[logs.length - 1].medewerker.voornaam + ' ' + logs[logs.length - 1].medewerker.achternaam : ''} - {logs[logs.length - 1].log_omschrijving} <Icon.InfoCircle onClick={() => setShowModalLogs(true)} /></td>
                                            :
                                            null
                                        }
                                    </tr>
                                    :
                                    null
                                }
                                
                                </tbody>
                            </table>
                        </div>
                            {
                                totalHours.includes("NaN") ?
                                null
                                :
                                <div className="footer-buttons">
                                    {
                                        totalHours.includes("-") ?
                                        <p className="error">Totaal uren is een negatief getal</p>
                                        :
                                        <>
                                        <button className="second" onClick={() => setShowModalOpmerking(true)}>Stuur opmerking</button>
                                        <button className="submit" onClick={() => submitRegistratie('goedgekeurd')}><Icon.CheckLg /><span>Uren akkoord</span></button>
                                        </>
                                    }
                                    {
                                        registraties.status === 'open' ?
                                        <p className="error">Let op! deze registratie is nog open</p>
                                        :
                                        null
                                    }
                                </div>
                            }
                    </div>
                </div>
                </>
            }

            <Rodal visible={showModalHelp} onClose={() => { setShowModalHelp(false); youtubePlayer.current.internalPlayer.pauseVideo(); }} width={500} enterAnimation="slideDown" leaveAnimation="slideUp" closeOnEsc={true}>
                <h3>Uitleg registraties controleren</h3>
                <YouTube videoId={videotutorials.uren_controleren} opts={opts} ref={youtubePlayer} />
            </Rodal>

            <Rodal visible={showModalOpmerking} onClose={() => { setShowModalOpmerking(false); setOpmerking(''); }} width={500} enterAnimation="slideDown" leaveAnimation="slideUp" closeOnEsc={true}>
                <h3>Opmerking sturen</h3>
                <form id="opmerking" onSubmit={sendOpmerking}>
                    <textarea value={opmerking} onChange={(e) => setOpmerking(e.target.value)} placeholder="Vul hier je opmerking in" required></textarea>
                    <input type="submit" value="Versturen" className="send" disabled={sendingOpmerking} />
                </form>
            </Rodal>

            <Rodal visible={showModalLogs} onClose={() => { setShowModalLogs(false); }} width={500} enterAnimation="slideDown" leaveAnimation="slideUp" closeOnEsc={true}>
            <div className="modal-logs">
                    <table id="logs">
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Door</th>
                                <th>Wanneer</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            logs.map((item, index) =>  {
                                return (
                                    <tr key={index} className="log">
                                        <td>{item.log_omschrijving}</td>
                                        <td>{item.medewerker.voornaam + ' ' + item.medewerker.achternaam}</td>
                                        <td>{moment(item.datum_tijd).format('DD-MM-YYYY HH:mm')}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
                </Rodal>

            <Toaster position="bottom-center" />
        </div>
        <BottomNav page="/uren-controleren" />
        <img src={svzArtwork} className="artwork" />
    </div>
    );
};
export default RegistratiesControleren;